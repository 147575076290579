/**
 * Render a rounded button with light blue/indigo background
 * @param {string|JSX.Element} children - the content to render inside the button
 * @param {object} rest - other attributes to pass on to the <button /> component (mostly the onClick attribute)
 * @returns {JSX.Element}
 * @constructor
 */
const ControlButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className=" px-4 py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-80 h-14">
      {children}
    </button>
  );
};

export default ControlButton;
