import Logo from "./Logo";
import Nav from "./Nav";

const Header = () => {
  return (
    <header className="max-w-[1440px] bg-white  top-0 z-[1] mx-auto  flex w-full flex-wrap h-24 items-center justify-between  border-gray-100 bg-background font-inter font-semibold backdrop-blur-[96px] md:px-32 px-4 sm:px-1">
      <Logo />
      <Nav />
    </header>
  );
};

export default Header;
