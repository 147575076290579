const SobreNosotros = () => {
  return (
    <div className="max-w-7xl w-full h-full flex flex-col justify-start items-center gap-10 sm:mt-24 mt-10 px-4 sm:px-0">
      <div className="sm:text-center text-left text-lime-600 sm:text-5xl text-3xl font-bold font-inter tracking-tight sm:mb-12 mb-4 w-full">
        Sobre nosotros
      </div>

      <div className="md:w-2/4 sm:w-3/4 w-full">
        <span className="text-neutral-700 text-xl font-bold font-inter leading-7 tracking-tight">
          “Cooperativito”
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
          {" "}
          es un proyecto desarrollado conjuntamente por{" "}
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter underline leading-7 tracking-tight">
          <a
            href="https://caf.org.uy/caf"
            target="_blank"
            rel="noopener noreferrer">
            Cooperativas Agrarias Federadas
          </a>
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
          {" "}
          (CAF) y las cooperativas agrarias socias{" "}
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter underline leading-7 tracking-tight">
          <a
            href="https://colaveco.com.uy/inicio"
            target="_blank"
            rel="noopener noreferrer">
            COLAVECO
          </a>
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
          {" "}
          y{" "}
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter underline leading-7 tracking-tight">
          <a
            href="https://elfogoncal.com.uy/web/"
            target="_blank"
            rel="noopener noreferrer">
            EL FOGÓN
          </a>
        </span>
        <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
          .  <br />
          <br />
          CAF es la única Federación que nuclea a cooperativas agrarias en el
          Uruguay. Representa los intereses de cooperativas que están
          distribuidas en todo el territorio nacional y que cuentan con más de
          10.000 productores asociados, de los cuales más del 80% integran la
          agricultura familiar. 
          <br />
          <br />
          Las cooperativas socias de CAF desarrollan sus actividades en
          diferentes cadenas globales de valor agroalimentarias y
          agroindustriales, abarcando casi la totalidad de los rubros
          agropecuarios explotados en el país. Producen: lácteos, carne (vacuna
          y ovina), lana, granos (soja, trigo, cebada, etc), frutas y verduras,
          vinos, flores, miel y semillas, entre otros productos.
          <br />
          <br />
          COLAVECO surgió hace 32 años en Nueva Helvecia (Colonia), a partir de
          la necesidad regional de contar con un laboratorio de apoyo a la
          producción local en aspectos vinculados a la calidad de los alimentos
          y la sanidad animal. Hoy es un laboratorio de ensayo y diagnóstico en
          el ámbito de veterinaria, agropecuaria, bromatológica y de salud
          pública.
          <br />
          <br />
          Por su parte, EL FOGÓN está ubicada en Sarandí del Yí (Durazno) y
          comenzó su actividad hace 61 años vinculada a la producción ovina.
          Actualmente, cuenta con socios en los departamentos de Durazno,
          Florida, y Treinta y Tres. En su mayoría se trata de productores
          familiares criadores de ovinos y bovinos de carne, quienes encuentran
          en la cooperativa la mejor opción para comercializar su producción de
          carne y lana, obtener asesoramiento, comprar insumos y apoyo a su
          actividad diaria.
        </span>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
        <div className="flex flex-col md:flex-row justify-center items-center gap-10 my-24">
          <div className="flex flex-row items-center sm:gap-10 gap-4">
            <span className="text-center text-lime-600 sm:text-xl text-xs font-bold font-inter capitalize tracking-wide">
              presentan:
            </span>

            <div className="flex flex-row gap-4 md:gap-10 items-center">
              <a
                href="https://caf.org.uy"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-20 w-14"
                  src="/caf.png"
                  alt="logo"
                />
              </a>
              <a
                href="https://www.colaveco.com.uy/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-32 h-auto w-20"
                  src="/colaveco.png"
                  alt="logo"
                />
              </a>
              <a
                href="https://elfogoncal.com.uy/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-28 w-20"
                  src="/fogon.png"
                  alt="logo"
                />
              </a>
            </div>
          </div>

          <div className="flex flex-row items-center gap-10 mt-10 md:mt-0">
            <span className="text-center text-lime-600 sm:text-xl text-xs font-bold font-inter capitalize tracking-wide">
              apoya:
            </span>

            <a
              href="https://www.inacoop.org.uy/"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/inacoop.svg" alt="inacoop" className="sm:w-60 w-32" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SobreNosotros;
