export const questions = [
  {
    id: 1,
    category: "Cadenas de valor agropecuarias",
    question: "¿Una vaca es un…?",
    correct_answer: "Bovino",
    incorrect_answers: ["Ovino", "Porcino", "Equino"],
  },
  {
    id: 2,
    category: "Cadenas de valor agropecuarias",
    question: "¿Una Oveja es …?",
    correct_answer: "Ovino",
    incorrect_answers: ["Equino", "Porcino", "Bovino"],
  },
  {
    id: 3,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cómo podemos saber la edad de una oveja?",
    correct_answer: "Por sus dientes",
    incorrect_answers: ["Por su cédula", "Alas", "Color"],
  },
  {
    id: 4,
    category: "Cadenas de valor agropecuarias",
    question:
      "¿Cómo se llama al proceso por el que se le quita la lana a las ovejas?",
    correct_answer: "Esquilar",
    incorrect_answers: ["Corte de pelo", "Peinar", "Teñir"],
  },
  {
    id: 5,
    category: "Cadenas de valor agropecuarias",
    question:
      "Normalmente, ¿cada cuanto tiempo se le realiza la esquila a los ovinos para quitar la lana?",
    correct_answer: "Una vez al año",
    incorrect_answers: ["Todos los días", "Cada dos días", "Cada 10 años"],
  },
  {
    id: 6,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cómo se llama al macho adulto de la especie bovina?",
    correct_answer: "Toro",
    incorrect_answers: ["Potro", "Oveja	", "Ternero"],
  },
  {
    id: 7,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cómo se llama a la cría de la oveja?",
    correct_answer: "Cordero",
    incorrect_answers: ["Ternero", "Zorro", "Cachorro"],
  },
  {
    id: 8,
    category: "Cadenas de valor agropecuarias",
    question:
      "Señale cuál de estás no es correcta. Con el procesamiento de la lana podemos hacer…",
    correct_answer: "Comida",
    incorrect_answers: ["Alfombras", "Frazadas", "Buzos"],
  },
  {
    id: 9,
    category: "Cadenas de valor agropecuarias",
    question:
      "¿Cuántos kilos de lana produce una oveja en un año, habitualmente?",
    correct_answer: "5 kilos",
    incorrect_answers: ["100 gramos", "20 kilos", "100 kilos"],
  },
  {
    id: 10,
    category: "Cadenas de valor agropecuarias",
    question:
      "¿Cuántas crías, llamadas corderos, produce una oveja habitualmente por año?",
    correct_answer: "1",
    incorrect_answers: ["30", "100", "10"],
  },

  {
    id: 11,
    category: "Cadenas de valor agropecuarias",
    question:
      "¿De qué se alimentan las ovejas habitualmente en nuestros campos?",
    correct_answer: "Pasto",
    incorrect_answers: ["Arroz", "Harina", "Helado"],
  },
  {
    id: 12,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cómo se llama al macho adulto de la especie ovina?",
    correct_answer: "Carnero",
    incorrect_answers: ["Potro", "Charabón", "Toro"],
  },
  {
    id: 13,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cómo se la llama a la cría de la vaca?",
    correct_answer: "Ternero",
    incorrect_answers: ["Zorro", "Lechón", "Cordero"],
  },

  {
    id: 14,
    category: "Cadenas de valor agropecuarias",
    question: "Aproximadamente, ¿cuántos ovinos hay en uruguay?",
    correct_answer: "6.000.000",
    incorrect_answers: ["100", "36.000.000", "70.000"],
  },

  {
    id: 15,
    category: "Cadenas de valor agropecuarias",
    question: "¿De qué se alimentan los corderos recién nacidos?",
    correct_answer: "Leche de la oveja",
    incorrect_answers: ["Galletitas", "Helado", "Jugos de naranja"],
  },
  {
    id: 16,
    category: "Cadenas de valor agropecuarias",
    question: "La lana le sirve a la oveja para…",
    correct_answer: "Protegerse",
    incorrect_answers: ["Estar a la moda", "Volar", "Nadar"],
  },
  {
    id: 17,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cuánta agua ingiere una oveja adulta por día?",
    correct_answer: "4 litros",
    incorrect_answers: ["200 litros", "1000 litros", "20 litros"],
  },
  {
    id: 18,
    category: "Cadenas de valor agropecuarias",
    question: "¿A qué se le llama cadena láctea?",
    correct_answer:
      "Al conjunto de empresas e instituciones que están unidas, dependen unas de otras y que hacen al sector lechero: productores, logística, industria, distribución, laboratorios, centros de investigación, comercio exterior, cooperativas, gremiales lecheras, organismos públicas, etc",
    incorrect_answers: [
      "A las cadenas que se usaban antes para atar los tarros lecheros que se llevaban en los camiones de la leche",
      "A las cadenas que se ponen como adornos en los jardines de las fábricas lácteas",
      "Al espacio de los supermercados donde están puestos y ordenados los productos lácteos",
    ],
  },
  {
    id: 19,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cuántos litros de leche consume un uruguayo anualmente?",
    correct_answer: "230",
    incorrect_answers: ["120", "360", "400"],
  },

  {
    id: 20,
    category: "Cadenas de valor agropecuarias",
    question: "¿Para cuántas personas en el mundo produce leche Uruguay?",
    correct_answer: "20 millones",
    incorrect_answers: ["10 mil", "1 millón", "40 millones"],
  },
  {
    id: 21,
    category: "Cadenas de valor agropecuarias",
    question:
      "El prestigio internacional de Uruguay como proveedor confiable de productos agrícolas le permite ingresar a 160 destinos y lo posiciona como uno de los principales exportadores mundiales de:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: ["Arroz y cebada", "Colza", "Malta y soja"],
  },
  {
    id: 22,
    category: "Cadenas de valor agropecuarias",
    question:
      "El trigo es uno de los productos que destaca en el rubro agrícola. La zona de este cultivo en Uruguay se concentra en:",
    correct_answer: "Colonia, Soriano, Río Negro, Paysandú y Salto",
    incorrect_answers: [
      "Canelones y Maldonado",
      "Salto, Tacuarembó y Rivera",
      "No se cultiva trigo en el país",
    ],
  },
  {
    id: 23,
    category: "Cadenas de valor agropecuarias",
    question:
      "Según el Instituto Nacional de Carnes, el consumo de carne es recomendable para tener una dieta saludable. ¿Cuántos kilos de carne (sumando carne vacuna, aviar, de cerdo y ovina) consume un uruguayo promedio por año?",
    correct_answer: "94 kilos por persona",
    incorrect_answers: [
      "20 kilos por persona",
      "50 kilos por persona",
      "120 kilos por persona",
    ],
  },
  {
    id: 24,
    category: "Cadenas de valor agropecuarias",
    question: "¿Cuáles son los principales destinos de las carnes uruguayas?",
    correct_answer: "China, Estados Unidos, Canadá y Unión Europea",
    incorrect_answers: ["Mercosur", "Japón", "India"],
  },
  {
    id: 25,
    category: "Cadenas de valor agropecuarias",
    question:
      "Unos 5 mil establecimientos se dedican a la producción de frutas y verduras en Uruguay.  El destino principal de esta producción es:",
    correct_answer: "Abastecer al mercado interno",
    incorrect_answers: [
      "Industrialización",
      "Exportación",
      "Ninguno de los anteriores",
    ],
  },
  {
    id: 26,
    category: "Cadenas de valor agropecuarias",
    question:
      "Las frutas y las verduras contribuyen a una vida y una alimentación saludables. Por eso, según la Guía Alimentaria para la población uruguaya, cada día debemos consumir:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Tres porciones de frutas",
      "Mitad del plato del almuerzo debería ser de verduras",
      "Mitad del plato de cena debería ser de verduras",
    ],
  },
  {
    id: 27,
    category: "Cooperativismo",
    question:
      "Una cooperativa son personas que se agrupan voluntariamente para:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Ayudarse a solucionar sus problemas socioeconómicos y mejorar su calidad de vida",
      "Producir bienes y servicios que les lleven a satisfacer sus necesidades comunes, basándose en la ayuda mutua",
      "Contribuir a formar seres responsables y solidarios",
    ],
  },
  {
    id: 28,
    category: "Cooperativismo",
    question: "¿En qué año y dónde surgió el cooperativismo moderno?",
    correct_answer:
      "En el año 1844, en la localidad de Rochdale, cerca de Manchester, Inglaterra",
    incorrect_answers: [
      "En Francia en el año 1789, con la revolución francesa",
      "En Uruguay en el año 1825, con la declaratoria de Independencia",
      "En Estados Unidos, en 1929",
    ],
  },
  {
    id: 29,
    category: "Cooperativismo",
    question:
      "El cooperativismo es una de las mayores fuerzas sociales y económicas a nivel mundial, y se basa en valores que tienen más de 150 años de historia. ¿Cuál de las siguientes opciones no corresponde a un valor cooperativo?",
    correct_answer: "Desigualdad, individualismo",
    incorrect_answers: [
      "Ayuda mutua y responsabilidad",
      "Igualdad y equidad",
      "Democracia y solidaridad",
    ],
  },
  {
    id: 30,
    category: "Cooperativismo",
    question: "¿Cuál de ellas es una cooperativa frutícola?",
    correct_answer: "JUMECAL",
    incorrect_answers: ["EL FOGÓN", "CALSAL", "COETC"],
  },
  {
    id: 31,
    category: "Cooperativismo",
    question: "¿Cuándo se celebra el Día Internacional de las Cooperativas?",
    correct_answer: "1er sábado de julio de cada año",
    incorrect_answers: [
      "1° de julio de cada año",
      "7 de julio",
      "12 de noviembre",
    ],
  },
  {
    id: 32,
    category: "Cooperativismo",
    question:
      "Según la ley en Uruguay: ¿Cuál es el número mínimo de personas para integrar una cooperativa?",
    correct_answer: "5 integrantes",
    incorrect_answers: [
      "2 integrantes",
      "10 integrantes",
      "No hay un número mínimo",
    ],
  },
  {
    id: 33,
    category: "Cooperativismo",
    question: "¿Cuántas cooperativas existen en Uruguay?",
    correct_answer: "Entre 3.500 y 4.000",
    incorrect_answers: [
      "No hay cooperativas en Uruguay",
      "Entre 500 y 1000",
      "Entre 2.500 y 3.000",
    ],
  },
  {
    id: 34,
    category: "Cooperativismo",
    question:
      "Las cooperativas agrarias se integran por diversos productores del campo que se organizan para:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Producir y conservar productos agropecuarios",
      "Comprar semillas, fertilizantes y maquinarias, acceder a modernas tecnologías",
      "Acondicionar, transformar y comercializar sus productos",
    ],
  },
  {
    id: 35,
    category: "Cooperativismo",
    question: "¿Qué es el INACOOP?",
    correct_answer: "Instituto Nacional del Cooperativismo",
    incorrect_answers: [
      "Una tienda",
      "Una cooperativa de transporte",
      "Ninguna de las anteriores",
    ],
  },
  {
    id: 36,
    category: "Cooperativismo",
    question:
      "Cooperativas Agrarias Federadas (CAF) es la única gremial que representa a las cooperativas agrarias del Uruguay. Está integrada por:",
    correct_answer: "Todos los anteriores",
    incorrect_answers: [
      "Más de 20 cooperativas agrarias y sociedades de fomento rural de diversos rubros productivos",
      "Cooperativas que están distribuidas en todo el territorio nacional",
      "Nuclea a más de 10.000 productores asociados",
    ],
  },
  {
    id: 37,
    category: "Cooperativismo",
    question: "¿Cuántos años cumple CAF en 2024?",
    correct_answer: "40",
    incorrect_answers: ["30", "50", "60"],
  },
  {
    id: 38,
    category: "Cooperativismo",
    question: "¿Cuál de ellas es una cooperativa láctea?",
    correct_answer: "COLEME",
    incorrect_answers: ["JUMECAL", "COPAGRAN", "CRADECO"],
  },
  {
    id: 39,
    category: "Ruralidad y ambiente",
    question:
      "¿Qué porcentaje de la población uruguaya reside en áreas rurales?",
    correct_answer: "4%",
    incorrect_answers: ["35%", "50%", "57%"],
  },
  {
    id: 40,
    category: "Ruralidad y ambiente",
    question: "¿Qué area del departamento de Montevideo es zona rural?",
    correct_answer: "60%",
    incorrect_answers: ["10%", "25%", "30%"],
  },
  {
    id: 41,
    category: "Ruralidad y ambiente",
    question: "¿Para cuántas personas en el mundo produce alimentos Uruguay?",
    correct_answer: "Alrededor de 30 millones",
    incorrect_answers: ["1 millón", "10 millones", "60 millones"],
  },
  {
    id: 42,
    category: "Ruralidad y ambiente",
    question: "¿Dónde nació el queso artesanal en Uruguay y cómo se llama?",
    correct_answer: "En Colonia Suiza y es el Queso Colonia",
    incorrect_answers: [
      "En Durazno y se llama Queso Dambo",
      "En Montevideo y es el Queso Magro",
      "En Costa Azul y es el Queso Azul",
    ],
  },

  {
    id: 43,
    category: "Ruralidad y ambiente",
    question:
      "¿Qué porcentaje del total del empleo nacional es generado por la economía agropecuaria?",
    correct_answer: "14%",
    incorrect_answers: ["2%", "7%", "35%"],
  },
  {
    id: 44,
    category: "Ruralidad y ambiente",
    question: "Un productor familiar es aquel que:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Trabaja y vive en el predio productivo junto a su familia",
      "Se sustenta económicamente de lo que produce",
      "Hace de la producción agropecuaria, más que una actividad económica, una forma de vida ligada a la tierra",
    ],
  },
  {
    id: 45,
    category: "Ruralidad y ambiente",
    question:
      "¿Qué porcentaje del total de unidades productivas en Uruguay está en manos de productores familiares?",
    correct_answer: "60%",
    incorrect_answers: ["15%", "30%", "50%"],
  },
  {
    id: 46,
    category: "Ruralidad y ambiente",
    question:
      "¿Qué porcentaje del suelo uruguayo está cubierto por pasturas naturales (campo natural)?",
    correct_answer: "60%",
    incorrect_answers: ["20%", "25%", "150%"],
  },
  {
    id: 47,
    category: "Ruralidad y ambiente",
    question: "Las pasturas naturales ocupan nuestra región desde hace más de…",
    correct_answer: "30 mil años",
    incorrect_answers: ["20 años", "200 años", "200 mil años"],
  },
  {
    id: 48,
    category: "Ruralidad y ambiente",
    question:
      "¿Por qué es importante la preservación de las pasturas naturales?",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Ayuda a conservar la biodiversidad, purifica el aire y el agua",
      "Controla el deterioro del suelo",
      "Provee de alimento al ganado",
    ],
  },
  {
    id: 49,
    category: "Ruralidad y ambiente",
    question:
      "El agua es un insumo fundamental para la producción y desempeña un papel importante en la seguridad alimentaria. Su uso es clave para:",
    correct_answer: "Todas las anteriores",
    incorrect_answers: ["Cultivar frutas y verduras", "Criar ganado", "Regar"],
  },
  {
    id: 50,
    category: "Ruralidad y ambiente",
    question: "¿Por qué es importante la conservación del agua?",
    correct_answer: "Todas las anteriores",
    incorrect_answers: [
      "Es esencial para la supervivencia humana y el mantenimiento de los ecosistemas",
      "Es necesaria para el consumo, la higiene, la producción de alimentos y la generación de energía",
      "Es un recurso limitado; Aunque la Tierra está cubierta en un 70% de agua, solo el 2,5% es agua dulce",
    ],
  },
  {
    id: 51,
    category: "Ruralidad y ambiente",
    question: '¿Qué significa el concepto de "una sola salud"?',
    correct_answer:
      "Salud es un concepto integral que abarca lo humano, animal y el ambiente",
    incorrect_answers: [
      "Cada ser vivo es único y su salud refiere sólo a él",
      "Sólo nos enfermamos de a una vez",
      "Sólo consideramos enfermedad cuando afecta a los humanos",
    ],
  },
  {
    id: 52,
    category: "Ciencia y tecnología en el agro",
    question: "¿Cuál es el único laboratorio cooperativo del Uruguay?",
    correct_answer: "COLAVECO",
    incorrect_answers: ["LATU", "INIA", "Laboratorios Unidos"],
  },
  {
    id: 53,
    category: "Ciencia y tecnología en el agro",
    question: "¿Para qué se hacen análisis de suelos?",
    correct_answer: "Medir el nivel de los nutrientes presentes",
    incorrect_answers: [
      "Para saber la cantidad de tierra que hay",
      "Para medir cuántos kilos de tierra hay",
      "Saber el nivel de contaminación",
    ],
  },
  {
    id: 54,
    category: "Ciencia y tecnología en el agro",
    question:
      "¿Porqué es importante analizar los alimentos que se les brinda a los animales?",
    correct_answer:
      "Para brindarles una correcta alimentación, equlibrada en nutrientes y sana para el organismo, que les permita un mejor desarrollo y una mayor producción",
    incorrect_answers: [
      "Porque los animales comen y no saben cuando se llenan",
      "Porque, como a los humanos, les gustan las cosas que les hacen mal y hay que controlarlos",
      "Para que siempre estén gordos y de buen color de piel",
    ],
  },
  {
    id: 55,
    category: "Ciencia y tecnología en el agro",
    question:
      "¿Cómo se llama la principal institución pública de investigación agropecuaria?",
    correct_answer: "INIA",
    incorrect_answers: [
      "Facultad de Ciencias",
      "Intendencias del Uruguay",
      "Instituto Clemente Estable",
    ],
  },
  {
    id: 56,
    category: "Ciencia y tecnología en el agro",
    question:
      "¿Cómo se llama la primera universidad tecnológica del Uruguay y cuyas sedes están todas en el interior del país?",
    correct_answer: "UTEC",
    incorrect_answers: [
      "Universidad de la República",
      "Universidad de la Empresa",
      "Udelar",
    ],
  },
  {
    id: 57,
    category: "Ciencia y tecnología en el agro",
    question: "¿Dónde está ubicada la primera Escuela de Lechería del Uruguay?",
    correct_answer: "Colonia Suiza",
    incorrect_answers: ["Montevideo", "Florida", "San José"],
  },
  {
    id: 58,
    category: "Ciencia y tecnología en el agro",
    question: "¿Existen tambos robotizados en Uruguay?",
    correct_answer: "Hay algunos funcionando",
    incorrect_answers: [
      "No se ha instalado ninguno",
      "Van a instalar uno",
      "Hay uno solo para investigación",
    ],
  },
  {
    id: 59,
    category: "Ciencia y tecnología en el agro",
    question:
      "¿Para qué se implementan los Planes de Uso y Manejo del Suelo en Uruguay?",
    correct_answer: "Para conservar el suelo y atenuar la erosión",
    incorrect_answers: [
      "Para ahorrar dinero en la siembra",
      "Para producir más granos",
      "Para tener un mapa del campo",
    ],
  },
  {
    id: 60,
    category: "Ciencia y tecnología en el agro",
    question: '¿Qué es la "Trazabilidad Ganadera" en Uruguay?',
    correct_answer:
      'Al sistema que permite identificar mediante un "chip" a cada animal',
    incorrect_answers: [
      "Una forma de venta de ganado en nuestro país",
      "Una regla que mide la altura de las vacas",
      "Una estancia turística del Uruguay",
    ],
  },
];
