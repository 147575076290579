import { useEffect, useState } from "react";
import Loader from "../components/Loader";

const RankingView = () => {
  const [ranking, setRanking] = useState([]);
  const [rankingTrivia, setRankingTrivia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePuzzle = await fetch(
          "https://cooperativito-api.vercel.app/players/puzzle-ranking"
        );
        if (!responsePuzzle.ok) throw new Error("Network response was not ok");

        const dataPuzzle = await responsePuzzle.json();
        setRanking(dataPuzzle.posicionesPuzzle.slice(0, 5));

        const responseTrivia = await fetch(
          "https://cooperativito-api.vercel.app/players/trivia-ranking"
        );
        if (!responseTrivia.ok) throw new Error("Network response was not ok");

        const dataTrivia = await responseTrivia.json();
        setRankingTrivia(dataTrivia.posicionesTrivia.slice(0, 5));
      } catch (error) {
        console.error("Error fetching the ranking:", error);
      } finally {
        setLoading(false); // Los datos se han cargado correctamente o ha ocurrido un error
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex items-center justify-center bg-lime-600 w-screen overflow-x-auto">
      <div className="bg-white rounded-[20px] flex my-20 w-full max-w-[794px]">
        <div className="flex flex-col justify-center items-center rounded-lg w-full p-4 sm:p-14">
          {ranking.length > 0 && (
            <div className="mt-5 w-full">
              <h2 className="text-2xl text-green-900 font-bold font-inter text-center mb-9">
                Ranking Puzzle
              </h2>
              <div className="overflow-x-auto">
                <table className="border-collapse text-green-900 font-inter w-full">
                  <thead className="text-xs">
                    <tr>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Posición
                      </th>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Departamento
                      </th>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Escuela
                      </th>
                      <th className="border border-transparent px-4 text-left">
                        Movimientos promedio
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-lime-600 text-xs font-bold font-inter">
                    {ranking.map((player, index) => (
                      <tr key={index}>
                        <td className="border-t border-green-900 px-4 py-2 text-green-900 font-bold text-center">
                          # {index + 1}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2">
                          {player.department}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2 font-bold">
                          {player.escuela}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2 font-bold text-center">
                          {player.averagePuzzleScore}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="mt-5 flex flex-col justify-evenly items-center rounded-lg w-full mb-14">
            <h2 className="text-2xl text-green-900 font-bold font-inter text-center mt-14 mb-9">
              Ranking Trivia
            </h2>
            <div className="overflow-x-auto">
              <table className="text-green-900 font-inter w-full">
                <thead className="text-xs">
                  <tr>
                    <th className="border border-transparent px-4 py-2 text-left">
                      Posición
                    </th>
                    <th className="border border-transparent px-4 py-2 text-left">
                      Departamento
                    </th>
                    <th className="border border-transparent px-4 py-2 text-left">
                      Escuela
                    </th>
                    <th className="border border-transparent px-4 text-left">
                      Puntaje promedio
                    </th>
                  </tr>
                </thead>
                <tbody className="text-lime-600 text-xs font-bold font-inter">
                  {rankingTrivia.map((player, index) => (
                    <tr key={index}>
                      <td className="border-t border-green-900 px-4 py-2 text-green-900 font-bold text-center">
                        # {index + 1}
                      </td>
                      <td className="border-t border-green-900 px-4 py-2">
                        {player.department}
                      </td>
                      <td className="border-t border-green-900 px-4 py-2 font-bold">
                        {player.escuela}
                      </td>
                      <td className="border-t border-green-900 px-4 py-2 font-bold text-center">
                        {player.averageTriviaScore}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingView;
