import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import {
  mobileNavContainerVariant,
  mobileNavListVariant,
  mobileNavExitProps,
} from "../../data/animationConfig";

const NavLinks = () => {
  const location = useLocation();
  return (
    <div className="md:-mr-14 mr-0">
      <NavLink
        to="/"
        className={`m-2 hover:text-green-900 ${
          location.pathname === "/"
            ? "text-[#78991C] border-b-2 border-[#78991C]"
            : "text-[#424242]"
        }`}>
        Inicio
      </NavLink>
      <NavLink
        to="/juegos"
        className={`m-2 hover:text-green-900 ${
          location.pathname === "/juegos"
            ? "text-[#78991C] border-b-2 border-[#78991C]"
            : "text-[#424242]"
        }`}>
        Juegos
      </NavLink>
      <NavLink
        to="/educacional"
        className={`m-2 hover:text-green-900  ${
          location.pathname === "/educacional"
            ? "text-[#78991C] border-b-2 border-[#78991C]"
            : "text-[#424242]"
        }`}>
        Padres y educadores
      </NavLink>
      <NavLink
        to="/sobre-nosotros"
        className={`m-2 hover:text-green-900  ${
          location.pathname === "/sobre-nosotros"
            ? "text-[#78991C] border-b-2 border-[#78991C]"
            : "text-[#424242]"
        }`}>
        Sobre nosotros
      </NavLink>
      <NavLink
        to="/ranking"
        className={`m-2 hover:text-green-900  ${
          location.pathname === "/ranking"
            ? "text-[#78991C] border-b-2 border-[#78991C]"
            : "text-[#424242]"
        }`}>
        Ranking
      </NavLink>
      <NavLink
        to="/contacto"
        className={`m-2 hover:text-green-900  ${
          location.pathname === "/contacto"
            ? "text-[#78991C] border-b-2 border-green-900"
            : "text-[#424242]"
        }`}>
        Contacto
      </NavLink>
    </div>
  );
};

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <nav className="flex flex-[1] items-center justify-end w-full">
        <div className="hidden justify-end md:flex">
          <NavLinks />
        </div>
        <div className="flex justify-end md:hidden z-50">
          <button onClick={toggleNavbar}>{isOpen ? <X /> : <Menu />}</button>
        </div>
      </nav>
      <AnimatePresence className="bg-lime-600" mode="wait">
        {isOpen && (
          <motion.div
            layout="position"
            key="nav-links"
            variants={mobileNavContainerVariant}
            initial="hidden"
            animate="show"
            className="md:hidden bg-lime-600 w-full h-screen z-40 left-0 fixed top-24">
            <motion.div
              className="flex flex-col items-center justify-center h-full"
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Inicio
              </NavLink>
              <div className="w-80 h-[0px] border border-white"></div>
            </motion.div>
            <motion.div
              className="flex flex-col items-center justify-center h-full"
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/juegos"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Juegos
              </NavLink>
              <div className="w-80 h-[0px] border border-white"></div>
            </motion.div>
            <motion.div
              className="flex flex-col items-center justify-center h-full bg-lime-600"
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/educacional"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Padres y educadores
              </NavLink>
              <div className="w-80 h-[0px] border border-white"></div>
            </motion.div>
            <motion.div
              className="flex flex-col items-center justify-center h-full bg-lime-600"
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/sobre-nosotros"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Sobre nosotros
              </NavLink>
              <div className="w-80 h-[0px] border border-white"></div>
            </motion.div>
            <motion.div
              className="flex flex-col items-center justify-center h-full "
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/ranking"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Ranking
              </NavLink>
              <div className="w-80 h-[0px] border border-white"></div>
            </motion.div>
            <motion.div
              className="flex flex-col items-center justify-center h-full"
              variants={mobileNavListVariant}
              {...mobileNavExitProps}>
              <NavLink
                to="/contacto"
                className="hover:text-green-900 h-9 text-center text-2xl font-light font-inter my-5 text-white">
                Contacto
              </NavLink>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Nav;
