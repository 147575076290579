import React from "react";
import GameForm from "../components/form/GameForm";

const FormView = () => {
  const handleFormSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <section
      className="bg-cover bg-center flex w-screen items-center justify-center py-24 px-5"
      style={{ backgroundImage: "url('bg.png')" }}>
      <div className="text-center inline-block bg-white sm:px-20 px-4 py-16 rounded-lg max-w-[794px] w-full">
        <div className="items-center justify-center mb-9">
          <img src="/juguemos.png" className="mx-auto my-2 w-52" alt="Logo" />
        </div>
        <h2 className=" text-gray-500 font-bold text-xl mb-9">
          Completá el formulario para comenzar a jugar
        </h2>
        <div className="h-0.5 bg-zinc-300 mb-9" />

        <GameForm onSubmit={handleFormSubmit} />
      </div>
    </section>
  );
};

export default FormView;
