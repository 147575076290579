import { configureStore, createSlice } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const initialState = {
  departamento: "",
  escuela: "",
  grado: "",
  nombre: "",
  avatar: "",
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setDepartamento: (state, action) => {
      state.departamento = action.payload;
    },
    setEscuela: (state, action) => {
      state.escuela = action.payload;
    },
    setGrado: (state, action) => {
      state.grado = action.payload;
    },
    setNombre: (state, action) => {
      state.nombre = action.payload;
    },
    setAvatarGlobal: (state, action) => {
      state.avatar = action.payload;
    },
  },
});

const persistedReducer = persistReducer(persistConfig, formSlice.reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export const {
  setDepartamento,
  setEscuela,
  setGrado,
  setNombre,
  setAvatarGlobal,
} = formSlice.actions;
