import React, { useState } from "react";
import { departments, schoolsByDepartment } from "../../data/list";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setDepartamento,
  setEscuela,
  setGrado,
  setNombre,
  setAvatarGlobal,
} from "../../store/store";

const GameForm = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [selectedAvatar, setSelectedAvatar] = useState("");

  const handlAvatarGlobalChange = (avatarPath) => {
    dispatch(setAvatarGlobal(avatarPath));
    setSelectedAvatar(avatarPath);
  };

  const handleNombreChange = (event) => {
    dispatch(setNombre(event.target.value));
  };

  const [grade, setGrade] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [filteredSchools, setFilteredSchools] = useState([]);

  const navigate = useNavigate();

  const handleDepartmentChange = (selectedDepartment) => {
    setDepartment(selectedDepartment);
    // Filtra las escuelas según el departamento seleccionado
    const schoolsForDepartment = schoolsByDepartment[selectedDepartment] || [];
    setFilteredSchools(schoolsForDepartment);
  };
  const handleSchoolChange = (selectedSchool) => {
    setSelectedSchool(selectedSchool);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar si algún campo está vacío
    // if (!department || !selectedSchool || !grade || !name || !selectedAvatar) {
    //   alert("Por favor, completa todos los campos.");
    //   return;
    // }

    if (!department) {
      alert("Por favor, seleccioná un departamento.");
      return;
    }
    if (!selectedSchool) {
      alert("Por favor, seleccioná una escuela.");
      return;
    }
    if (!grade) {
      alert("Por favor, seleccioná un grado.");
      return;
    }
    if (!name) {
      alert("Por favor, ingresá tu nombre.");
      return;
    }
    if (!selectedAvatar) {
      alert("Por favor, seleccioná un avatar.");
      return;
    }

    dispatch(setDepartamento(department));
    dispatch(setEscuela(selectedSchool));
    dispatch(setGrado(grade));
    dispatch(setNombre(name));
    dispatch(setAvatarGlobal(selectedAvatar));

    const formData = {
      department,
      selectedSchool,
      grade,
      name,
      avatar: selectedAvatar,
    };

    onSubmit(formData);
    navigate("/seleccion-juegos");
  };
  return (
    <form onSubmit={handleSubmit} className=" sm:w-full">
      <label className="block font-bold text-left mb-1 md:mb-2  text-[#094927] text-lg">
        <div className="mb-2">Departamento</div>

        <select
          id="department"
          value={department}
          onChange={(e) => handleDepartmentChange(e.target.value)}
          className=" block appearance-none min-w-full bg-white border border-gray-400 hover:border-gray-500 sm:px-4 px-1 py-2 mb-9 rounded  leading-tight focus:outline-none focus:shadow-outline">
          <option className="text-zinc-300" disabled>
            Seleccioná un departamento
          </option>
          {departments.map((departmentOption) => (
            <option key={departmentOption} value={departmentOption}>
              {departmentOption}
            </option>
          ))}
        </select>
      </label>

      <label className="block text-[#094927] font-bold text-left mb-1 md:mb-2 text-lg w-full">
        <div className="mb-2">Escuela</div>

        <select
          id="school"
          value={selectedSchool}
          onChange={(e) => handleSchoolChange(e.target.value)}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 sm:px-4 px-1 py-2  rounded  leading-tight focus:outline-none focus:shadow-outline mb-9">
          <option className="text-zinc-300" disabled>
            Seleccioná una escuela
          </option>
          {filteredSchools.map((schoolOption) => (
            <option key={schoolOption} value={schoolOption}>
              {schoolOption}
            </option>
          ))}
        </select>
      </label>

      <label className="block text-[#094927] font-bold text-left mb-2 md:mb-2 text-lg leading-7">
        <div className="mb-2">Grado</div>

        <input
          id="grade"
          type="text"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 mb-9  rounded leading-tight focus:outline-none focus:shadow-outline"
        />
      </label>

      <label className="block text-[#094927] font-bold text-left mb-2 text-lg leading-7">
        <div className="mb-2">Nombre o equipo</div>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            handleNombreChange(e);
          }}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 mb-9  rounded leading-tight focus:outline-none focus:shadow-outline"
        />
      </label>

      <label className="block text-[#094927] font-bold text-left mb-1 md:mb-0 text-lg">
        <div className="mb-2">Avatar</div>

        <div className="flex items-center space-x-2 justify-between mb-9">
          <img
            src="/avatar/caballo.png"
            alt="Avatar 1"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20 rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/caballo.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/caballo.png")}
          />
          <img
            src="/avatar/carpincho.png"
            alt="Avatar 2"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20  rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/carpincho.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/carpincho.png")}
          />
          <img
            src="/avatar/mulita.png"
            alt="Avatar 2"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20  rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/mulita.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/mulita.png")}
          />
          <img
            src="/avatar/ñandú.png"
            alt="Avatar 4"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20  rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/ñandú.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/ñandú.png")}
          />
          <img
            src="/avatar/oveja.png"
            alt="Avatar 5"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20  rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/oveja.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/oveja.png")}
          />
          <img
            src="/avatar/vaca.png"
            alt="Avatar 6"
            className={`cursor-pointer w-12 h-12 sm:w-20 sm:h-20  rounded-full border border-gray-400 ${
              selectedAvatar === "/avatar/vaca.png"
                ? "border-[#094927] border-4"
                : "hover:border-gray-500"
            }`}
            onClick={() => handlAvatarGlobalChange("/avatar/vaca.png")}
          />
        </div>
      </label>
      <br />
      <button
        type="submit"
        className="block appearance-none w-full bg-[#094927] text-white border px-4 py-2 mb-2 pr-8 rounded-md text-xl font-bold">
        Comenzar a jugar
      </button>
    </form>
  );
};

export default GameForm;
