import React, { useContext, useState, useEffect } from "react";
import GameBoard from "../components/puzzle/board/GameBoard";
import GameControls from "../components/puzzle/controls/GameControls";
import { GameContext } from "../store/GameContext";

/**
 * Game Screen component, renders the puzzle in GameBoard and the game information and buttons in GameControls
 * @returns {JSX.Element}
 * @constructor
 */
const GameScreen = () => {
  const { puzzleImage, game } = useContext(GameContext);
  const [message, setMessage] = useState(
    "Mirá la imagen antes de comenzar para recordarla visualmente"
  );

  useEffect(() => {
    if (game?.moves?.length ?? 0) {
      setMessage(
        "Hacé click sobre el cuadrado que querés que se mueva, se moverá al espacio que está vacío"
      );
    } else {
      setMessage(
        "Mirá la imagen antes de comenzar para recordarla visualmente"
      );
    }
  }, [game.moves?.length]);

  const showImage = game?.moves?.length >= 30;

  return (
    <div className="md:flex h-auto w-screen items-start justify-evenly bg-lime-600 py-14">
      <div className="sm:w-52 w-full flex flex-col sm:justify-between h-full">
        <div className="flex-1 sm:pt-16 pt-0">
          <p className="sm:p-2 p-6 text-white text-base font-normal font-inter">
            {message}
          </p>
        </div>
        {showImage && (
          <div className="sm:w-full w-40 hidden sm:block">
            <img src={puzzleImage} alt="puzzle" className="w-full" />
          </div>
        )}
      </div>
      <GameBoard />

      <GameControls />
    </div>
  );
};

export default GameScreen;
