import React from "react";

const PadresYEducadores = () => {
  const onButtonClick = () => {
    const pdfUrl = "/libroAprendiendo.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "libroAprendiendo.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="sm:items-center items-start justify-center flex sm:inline-flex mt-10">
        <div className="max-w-7xl w-full h-full flex-col justify-start sm:items-center items-start sm:gap-10 gap-4 inline-flex">
          <div className="h-28 flex-col justify-center sm:items-center items-start gap-8 flex">
            <div className="flex-col justify-center sm:items-center items-start sm:gap-6 gap-4 flex">
              <div className="sm:text-center text-left text-neutral-500 text-xl font-normal font-inter tracking-tight pl-4 sm:pl-0">
                Información de interés para
              </div>
              <div className="sm:text-center text-left  text-lime-600 sm:text-5xl text-3xl font-bold font-inter tracking-tight pl-4 sm:pl-0">
                Padres y Educadores
              </div>
            </div>
          </div>
          <div className="sm:w-2/4 w-full max-w-screen text-neutral-700 text-xl font-normal font-inter sm:leading-7 tracking-tight leading-snug px-4">
            En Uruguay existe el desafío de reforzar el vínculo entre habitantes
            rurales y urbanos. Tender puentes, propiciar diálogos y acercar
            conocimiento sobre las oportunidades que el agro representa para
            todo el país, pero en especial para las nuevas generaciones. Ese es
            el objetivo más importante que nos proponemos con esta iniciativa. 
            <br />
            <br />
            También buscamos que los niños descubran una herramienta maravillosa
            que mejora cada rincón del mundo: las cooperativas. Gracias a las
            cooperativas podemos alcanzar lo que nos proponemos y necesitamos y
            que cada uno por separado no podría conseguir. Son tan valiosas para
            la vida que es bueno que la conozcan grandes y chicos, que sus
            valores se compartan y apliquen en la casa, en la escuela, en el
            barrio, en el trabajo. 
            <br />
            <br />A través de Cooperativito, queremos que junto a nuestros niños
            descubramos la potencia del cooperativismo agrario. ¡Contamos con
            Ustedes!
          </div>

          <div className="flex-col justify-start items-center gap-10 mt-10 inline-flex">
            <div className="sm:text-center text-left text-lime-600 sm:text-4xl text-2xl font-bold font-inter tracking-tight sm:w-2/4 w-full px-4 sm:px-0">
              ¿Qué temas se pueden trabajar en el aula a través de
              “Cooperativito”?
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 px-4 sm:px-0">
              {/* Primera fila */}
              <div className="sm:w-80 w-full text-neutral-500 text-xl font-inter leading-7 tracking-tight">
                <span className="font-bold ">
                  Inspirarse en los principios y valores cooperativos,
                </span>

                <span>
                  {" "}
                  como la igualdad, la responsabilidad, la democracia, la
                  solidaridad, la ayuda mutua y la equidad. 
                </span>
              </div>
              <div className="w-[334px] h-[0px] border border-lime-600 sm:hidden block"></div>
              <div className="w-80 text-neutral-500 text-xl font-inter leading-7 tracking-tight ">
                <div className="w-80 ">
                  <span className="font-bold ">
                    Conocer qué son las cadenas de valor agroindustriales
                  </span>
                  <span>
                    {" "}
                    y el rol que juegan las cooperativas agrarias en ellas.
                  </span>
                </div>
              </div>
              <div className="w-[334px] h-[0px] border border-lime-600 sm:hidden block"></div>
              <div className="w-80 text-neutral-500 text-xl font-inter leading-7 tracking-tight ">
                <div className="w-80 ">
                  <span className="font-bold ">
                    Saber que en Uruguay se produce el alimento de calidad que
                    permite la vida de millones de personas en Uruguay y en el
                    mundo.
                  </span>
                  <span>
                    {" "}
                    ¡Sentirnos orgullosos del trabajo de nuestros productores!
                  </span>
                </div>
              </div>

              {/* Segunda fila */}
              <div className="w-80 text-neutral-500 text-xl font-inter leading-7 tracking-tight ">
                <div className="w-80 h-[0px] border border-lime-600 mb-12"></div>
                <div className="w-80 ">
                  <span>Descubrir la </span>
                  <span className="font-bold ">
                    {" "}
                    importancia que las cooperativas agrarias tienen en el
                    desarrollo de las distintas comunidades de nuestro país
                  </span>
                  <span>
                    {" "}
                    y su permanente compromiso con el cuidado ambiental.
                  </span>
                </div>
              </div>
              <div className="w-80 text-neutral-500 text-xl font-inter leading-7 tracking-tight ">
                <div className="w-80 ">
                  <div className="w-80 h-[0px] border border-lime-600 mb-12"></div>
                  <span className="font-bold ">
                    Ciencia y tecnología de primer nivel en Uruguay.
                  </span>
                  <span>
                    {" "}
                    Conocer más sobre el desarrollo científico presente en el
                    agro uruguayo.
                  </span>
                </div>
              </div>
              <div className="w-80 text-neutral-500 text-xl font-inter leading-7 tracking-tight ">
                <div className="w-80 ">
                  <div className="w-80 h-[0px] border border-lime-600 mb-12"></div>
                  <span className="font-bold ">¡Hay lugar para todos!</span>
                  <span>
                    {" "}
                    El cooperativismo agrario uruguayo genera empleo de calidad
                    en distintos puntos del país para miles de personas de muy
                    distintos perfiles profesionales.
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:w-2/4 w-full flex-col justify-start items-start gap-y-12 inline-flex mb-12 pl-4 sm:pl-0">
              <div className="text-lime-600 sm:text-4xl text-2xl font-bold font-inter tracking-tight">
                Información sobre cómo los juegos benefician al aprendizaje:
              </div>
              <div>
                <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                  Según{" "}
                </span>
                <span className="text-neutral-700 text-xl font-normal font-inter underline leading-7 tracking-tight">
                  <a
                    href="https://www.unicef.org/sites/default/files/2019-01/UNICEF-Lego-Foundation-Aprendizaje-a-traves-del-juego.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    UNICEF
                  </a>
                </span>
                <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                  , el aprendizaje a través del juego conlleva múltiples
                  beneficios para los niños. Estos son solo algunos de ellos:
                  <br />
                  <br />
                </span>
                <ul className="list-disc pl-4">
                  <li>
                    <span className="text-neutral-700 text-xl font-bold font-inter leading-7 tracking-tight">
                      El juego es divertido.
                    </span>
                    <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                      {" "}
                      “Cuando vemos jugar a los niños, a menudo observamos que
                      sonríen o ríen abiertamente. Obviamente el juego puede
                      tener sus retos y sus frustraciones, pero la sensación
                      general es de disfrute, motivación, emoción y placer. 
                      <br />
                    </span>
                  </li>

                  <br />

                  <li>
                    <span className="text-neutral-700 text-xl font-bold font-inter leading-7 tracking-tight">
                      El juego invita a la participación activa.
                    </span>
                    <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                      {" "}
                      “Si observamos cómo juegan los niños, veremos que se
                      implican profundamente en el juego, a menudo combinando la
                      actividad mental y verbal”. La trivia, por ejemplo,
                      permite aprender a través de la experiencia, brindando una
                      retroalimentación inmediata que permite seguir
                      incorporando conocimiento.
                      <br />
                    </span>
                  </li>

                  <br />
                  <li>
                    <span className="text-neutral-700 text-xl font-bold font-inter leading-7 tracking-tight">
                      El juego es socialmente interactivo.{" "}
                    </span>
                    <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                      “Permite a los niños comunicar ideas y entender a los
                      demás mediante la interacción social, sentando las bases
                      para construir un conocimiento más profundo y unas
                      relaciones más sólidas”. Por este motivo, recomendamos que
                      esta herramienta lúdica se utilice en el aula con la
                      supervisión de un docente y que se juegue en equipos. 
                      <br />
                    </span>
                  </li>

                  <br />
                  <li>
                    <span className="text-neutral-700 text-xl font-bold font-inter leading-7 tracking-tight">
                      El juego contribuye al aprendizaje.
                    </span>
                    <span className="text-neutral-700 text-xl font-normal font-inter leading-7 tracking-tight">
                      {" "}
                      “Ni el juego ni el aprendizaje son estáticos. Los niños
                      juegan para practicar competencias, probar posibilidades,
                      revisar hipótesis y descubrir nuevos retos, lo cual se
                      transforma en un aprendizaje más profundo”.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className=" w-full h-auto flex-col justify-start sm:items-center items-start gap-14 inline-flex  ">
              <div className="text-center text-lime-600 sm:text-4xl text-2xl font-bold font-inter tracking-tight pl-4 sm:pl-0">
                Recursos adicionales
              </div>
              <div className="items-center flex w-screen">
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/ElVoid5de28?si=iWNmsk2RnbVmDtKQ"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen></iframe>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-center items-start gap-4 sm:ml-48 ml-0 pl-4 sm:pl-0">
                <div className="flex-col justify-start items-start gap-4 sm:inline-flex block">
                  <div className="text-lime-600 sm:text-3xl text-2xl font-bold font-inter tracking-tight mt-10">
                    Libro “Aprendiendo <br /> a cooperar”
                  </div>
                  <div className="relative sm:block hidden">
                    <button
                      onClick={onButtonClick}
                      className="w-48 h-10 bg-green-900 rounded-md flex items-center justify-center text-white text-sm font-bold font-inter ">
                      Descargar
                    </button>
                  </div>
                </div>
                <img
                  className="sm:w-2/4 sm:h-2/4 w-full flex-initial "
                  src="/aprendiendo.png"
                  alt="aprendiendo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen flex justify-center sm:hidden mb-4 px-4 sm:px-0">
        <button
          onClick={onButtonClick}
          className="sm:w-96 w-full h-10 bg-green-900 rounded-md flex items-center justify-center text-white text-sm font-bold font-inter ">
          Descargar
        </button>
      </div>
    </>
  );
};

export default PadresYEducadores;
