import React from "react";
import ControlButton from "../components/puzzle/controls/ControlButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Juegos = () => {
  const navigate = useNavigate();
  const name = useSelector((state) => state.nombre);
  const avatar = useSelector((state) => state.avatar);

  return (
    <div className="sm:h-[calc(100vh-228px)] h-screen w-screen flex items-center justify-center bg-[#78991C]">
      <div className="justify-center items-center flex-col flex">
        <img
          src={avatar}
          alt="avatar"
          className="w-24 h-24  rounded-full border border-gray-400 items-center"
        />

        <h2 className="text-center text-white sm:text-[56px] text-5xl font-bold font-inter leading-[68px]">
          Hola, {name}
        </h2>
        <p className="w-[630px] text-center text-white text-xl font-medium font-inter">
          ¿A qué te gustaría jugar?
        </p>
        <div className="flex flex-col sm:flex-row sm:space-x-4 w-full mt-10 sm:justify-around justify-center items-center text-xl font-bold space-y-4 sm:space-y-0">
          <ControlButton onClick={() => navigate("/puzzle")}>
            Puzzle
          </ControlButton>

          <ControlButton onClick={() => navigate("/trivia")}>
            Trivia
          </ControlButton>
        </div>
      </div>
    </div>
  );
};

export default Juegos;
