import React, { useEffect, useState } from "react";
// import ControlButton from "../components/puzzle/controls/ControlButton";
// import { useNavigate } from "react-router-dom";
import Question from "../components/trivia/Question";
import { questions } from "../data/questions";
import { useSelector } from "react-redux";

// Function to shuffle questions for each category and limit them to 20
const shuffleArray = (array) => {
  const newArray = [...array]; // Create a copy of the original array
  return newArray.sort(() => Math.random() - 0.5).slice(0, 20);
};

const Trivia = () => {
  // const navigate = useNavigate();
  const name = useSelector((state) => state.nombre);

  const [questionsFiltered, setQuestionsFiltered] = useState(
    questions.filter((question) => question)
  );
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [activeQuiz, setActiveQuiz] = useState(false);

  useEffect(() => {
    const initializeQuestions = () => {
      if (questionsFiltered.length > 0 && !activeQuiz) {
        const newQuestions = shuffleArray(questionsFiltered);
        setQuestionsFiltered(newQuestions);
      }
    };

    initializeQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty to run only on mount

  return (
    <div className="w-screen flex flex-col items-center justify-center gap-10 bg-lime-600 min-h-screen">
      {activeQuiz ? (
        <Question
          filteredQuestion={questionsFiltered[indexQuestion]}
          setIndexQuestion={setIndexQuestion}
          indexQuestion={indexQuestion}
          questionsFiltered={questionsFiltered}
          setActiveQuiz={setActiveQuiz}
        />
      ) : (
        <>
          <div className="flex flex-col gap-5 ">
            <div className="flex justify-center items-center"></div>
          </div>
          <div className="sm:w-5/12 w-full text-center text-white sm:text-6xl text-4xl font-bold font-inter leading-[68px]">
            ¿Comenzamos la trivia, {name}?
          </div>
          <button
            className="text-white bg-green-900 py-2 rounded-md font-bold px-5 transition-all w-80 h-16 text-xl"
            onClick={() => setActiveQuiz(true)}>
            Empezar
          </button>
        </>
      )}
    </div>
  );
};
export default Trivia;
