/**
 * Renders an individual game information, with an Icon, a Label and a Value
 * @param {?JSX.Element} icon icon to render
 * @param {string} label information label
 * @param {string|number|JSX.Element} children information content (value)
 * @returns {JSX.Element}
 * @constructor
 */
const GameInfo = ({ icon = null, label = "", children }) => {
  return (
    <div
      className="flex flex-col justify-start items-start gap-2 text-white
            sm:py-1 sm:px-2 sm:pt-0 pt-8 px-8 rounded-xl flex-1 md:flex-none">
      <div className="flex flex-row items-center gap-2 justify-start text-sm md:text-md">
        <div className="text-xl md:text-5xl font-bold font-inter">{icon} </div>

        <span
          className="text-white
text-[15px]
font-bold
font-inter
leading-7">
          {!!label && label}
        </span>
      </div>
      <div className="font-normal font-inter text-left text-4xl sm:text-5xl">
        {children}
      </div>
    </div>
  );
};

export default GameInfo;
