import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GameContext } from "../../store/GameContext";

// Función para extraer el nombre base del archivo de una URL completa
const extractBaseFilename = (url) => {
  const filenameWithHash = url.split("/").pop();
  const nameParts = filenameWithHash.split(".");
  if (nameParts.length > 2) {
    nameParts.pop();
    nameParts.pop();
  } else {
    nameParts.pop();
  }
  return nameParts.join(".");
};

const Ranking = () => {
  const { game, puzzleImage, availableImages, pickNewImage } =
    useContext(GameContext);
  const puzzleImageName = extractBaseFilename(puzzleImage);

  const [ranking, setRanking] = useState([]);
  const additionalPuzzles = availableImages.filter(
    (img) => extractBaseFilename(img) !== puzzleImageName
  );

  console.log("availableImages:", availableImages);
  console.log("puzzleImage:", puzzleImage);
  console.log("additionalPuzzles:", additionalPuzzles);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://cooperativito-api.vercel.app/players/puzzle-ranking")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const top5Ranking = data.posicionesPuzzle.slice(0, 5);
        setRanking(top5Ranking);
        console.log(
          "Ranking actualizado con posicionesPuzzle:",
          data.posicionesTrivia
        );
      })
      .catch((error) => {
        console.error("Error fetching the ranking:", error);
      });
  }, []);

  const handlePuzzleClick = (puzzle) => {
    pickNewImage(puzzle);
    navigate("/puzzle");
  };

  return (
    <div className="flex items-center justify-center bg-lime-600 w-screen overflow-x-auto">
      <div className="bg-white rounded-[20px] flex my-20 w-full max-w-[800px]">
        <div className="flex flex-col justify-evenly items-center rounded-lg w-full p-4 sm:p-10">
          <h1 className="sm:text-2xl text-5xl font-bold text-lime-600 mb-7">
            ¡Completado!
          </h1>
          <h1 className="text-2xl font-bold text-green-900 mb-7">
            Acertaron en {game.moves.length} movimientos
          </h1>
          <div className="w-full mb-5">
            <img src={puzzleImage} alt="puzzle" className="w-full" />
          </div>
          <h1 className="text-2xl font-bold text-green-900 mb-7">
            Armar otros puzzles
          </h1>
          <div className="w-full mb-5 flex justify-around">
            {additionalPuzzles.map((puzzle, index) => (
              <img
                key={index}
                src={require(`../../assets/images/${puzzle}`)}
                alt={`puzzle-${index}`}
                className="w-1/3 cursor-pointer"
                onClick={() => handlePuzzleClick(puzzle)}
              />
            ))}
          </div>
          {ranking.length > 0 && (
            <div className="mt-5 w-full overflow-x-auto">
              <h2 className="sm:text-xl text-4xl text-green-900 font-bold font-inter text-center">
                Ranking
              </h2>
              <div className="overflow-x-auto">
                <table className="border-collapse text-green-900 font-inter w-full min-w-[600px]">
                  <thead className="text-xs">
                    <tr>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Posición
                      </th>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Departamento
                      </th>
                      <th className="border border-transparent px-4 py-2 text-left">
                        Escuela
                      </th>
                      <th className="border border-transparent px-4 text-left">
                        Movimientos promedio
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-lime-600 text-xl">
                    {ranking.map((player, index) => (
                      <tr key={index}>
                        <td className="border-t border-green-900 px-4 py-2 text-green-900 font-bold text-center">
                          # {index + 1}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2">
                          {player.department}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2 font-bold">
                          {player.escuela}
                        </td>
                        <td className="border-t border-green-900 px-4 py-2 font-bold text-center">
                          {player.averagePuzzleScore}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ranking;
