/**
 * Button rendered on top of the board over a gradient background
 * @component
 * @param {string|JSX.Element} children - content of the button
 * @param {function} onClick - callback for button click
 * @returns {JSX.Element}
 * @constructor
 */
const BoardAction = ({ children, onClick }) => {
  return (
    <div className="z-20 absolute w-full h-full flex items-center justify-center">
      <div
        className="bg-transoarent
                            w-full flex items-center justify-center py-16">
        <button
          className="bg-[#094927] text-white px-4 py-2 text-lg md:text-xl rounded-md flex flex-row gap-2 items-center justify-center font-inter w-80 h-16"
          onClick={onClick}>
          {children}
        </button>
      </div>
    </div>
  );
};

export default BoardAction;
