import Juegos from "./screens/Juegos";
import Trivia from "./screens/Trivia";
import Puzzle from "./screens/Puzzle";
import Home from "./screens/Home";
import PadresYEducadores from "./screens/PadresYEducadores";
import SobreNosotros from "./screens/sobre Nosotros";
import { Navigate, Route, Routes } from "react-router-dom";
import FoundationLayout from "./components/layout/FoundationLayout";
import Contacto from "./screens/Contacto";
import { Provider } from "react-redux";
import Ranking from "./components/puzzle/Results-puzzle";
import RankingView from "./screens/Ranking";

import { store } from "./store/store";

import FormView from "./screens/FormView";
//import { Import } from "lucide-react";

/**
 * Puzzle Game wrapper
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  return (
    <Provider store={store}>
      <FoundationLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/juegos" element={<FormView />} />
          <Route path="/seleccion-juegos" element={<Juegos />} />
          <Route path="/trivia" element={<Trivia />} />
          <Route path="/puzzle" element={<Puzzle />} />
          <Route path="/educacional" element={<PadresYEducadores />} />
          <Route path="/sobre-nosotros" element={<SobreNosotros />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/puzzle/ranking" element={<Ranking />} />
          <Route path="/ranking" element={<RankingView />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </FoundationLayout>
    </Provider>
  );
}

export default App;
