import Header from "../navbar/Header";
import Footer from "../Footer";
/**
 * Outer wrapper of the entire app,
 * places the app content in the center of the screen and sets a top level background
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const FoundationLayout = ({ children }) => {
  return (
    <>
      <Header />

      <div className="flex items-center justify-center">
        <div className="flex-col md:flex-row gap-6 rounded-lg">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default FoundationLayout;
