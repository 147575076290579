import { NavLink } from "react-router-dom";

const LogoFooter = () => {
  return (
    <NavLink to="/" className="inline-block ml-2">
      <svg
        width="172"
        height="22"
        viewBox="0 0 258 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.95624 1.42891C9.50817 1.30356 10.1604 1.24089 10.9131 1.24089C12.6441 1.24089 14.4128 1.47904 16.2191 1.95535C17.7494 2.38151 18.6149 2.69487 18.8156 2.89542C18.9411 3.04583 19.0038 3.20877 19.0038 3.38425C19.0038 3.55973 18.8156 4.22405 18.4393 5.3772C17.9125 7.03173 17.5989 7.90913 17.4985 8.0094C17.4735 8.03447 17.3982 8.047 17.2728 8.047C16.8212 8.047 16.1814 7.90913 15.3536 7.63337C13.9487 7.20721 12.6943 6.99412 11.5904 6.99412C10.4866 6.99412 9.58343 7.20721 8.88098 7.63337C7.90257 8.23502 7.12485 9.31297 6.54784 10.8672C5.99592 12.3964 5.71995 14.2139 5.71995 16.3196C5.71995 16.445 5.71995 16.633 5.71995 16.8837C5.74504 17.1594 5.75758 17.36 5.75758 17.4853C5.88302 19.6412 6.18407 21.2957 6.66074 22.4489C7.16249 23.602 7.92765 24.4168 8.95624 24.8931C10.0601 25.4195 12.895 25.3318 17.4609 24.6298C17.5362 24.8053 17.7118 25.7454 17.9877 27.4501C18.2637 29.1547 18.3891 30.0447 18.3641 30.1199C18.2386 30.2703 18.0128 30.3831 17.6867 30.4583C17.3606 30.5335 16.8714 30.6212 16.2191 30.7215C15.8679 30.7716 15.5919 30.8092 15.3912 30.8343C14.187 31.0098 12.9953 31.0975 11.8162 31.0975C8.32905 31.0975 5.59452 30.0321 3.6126 27.9013C1.2042 25.3192 0 21.6718 0 16.9589C0 16.9087 0 16.8335 0 16.7333C0.0250875 12.5719 0.815344 9.16255 2.37077 6.50529C3.9262 3.82295 6.12135 2.13083 8.95624 1.42891Z"
          fill="#094927"
        />
        <path
          d="M36.0508 7.55817C35.6494 7.43282 35.248 7.34508 34.8466 7.29495C34.4702 7.24481 34.0814 7.21974 33.68 7.21974C32.0995 7.21974 30.5942 7.69604 29.1642 8.64865C27.0569 10.1277 26.0032 12.2209 26.0032 14.9283C26.0032 16.5076 26.4548 17.9616 27.3579 19.2902C28.2862 20.5938 29.4778 21.5339 30.9329 22.1105C31.6103 22.3611 32.526 22.4865 33.68 22.4865C36.5149 22.1857 38.5721 21.1453 39.8515 19.3655C40.7045 18.1872 41.2188 16.6706 41.3944 14.8155C41.3944 13.1108 40.8801 11.5817 39.8515 10.228C38.8229 8.89933 37.556 8.0094 36.0508 7.55817ZM30.0674 2.06816C31.3218 1.7172 32.5636 1.54172 33.7929 1.54172C36.0508 1.54172 38.2585 2.15589 40.416 3.38425C42.3979 4.51234 44.0788 6.1794 45.4586 8.38543C46.5123 10.2906 47.1018 12.4967 47.2272 15.0035C47.2272 16.8586 46.876 18.5758 46.1736 20.1551C45.4711 21.7595 44.4174 23.2135 43.0125 24.517C40.4787 26.9738 37.3804 28.2146 33.7176 28.2397C28.7754 28.2397 24.9495 26.0838 22.2401 21.772C21.1864 19.8919 20.5592 17.6107 20.3585 14.9283C20.3585 12.0204 21.2491 9.38817 23.0303 7.03173C24.7865 4.67528 27.1321 3.02076 30.0674 2.06816Z"
          fill="#094927"
        />
        <path
          d="M64.0861 7.55817C63.6847 7.43282 63.2833 7.34508 62.8819 7.29495C62.5055 7.24481 62.1167 7.21974 61.7153 7.21974C60.1348 7.21974 58.6295 7.69604 57.1995 8.64865C55.0922 10.1277 54.0385 12.2209 54.0385 14.9283C54.0385 16.5076 54.4901 17.9616 55.3932 19.2902C56.3215 20.5938 57.5131 21.5339 58.9682 22.1105C59.6456 22.3611 60.5613 22.4865 61.7153 22.4865C64.5502 22.1857 66.6074 21.1453 67.8868 19.3655C68.7398 18.1872 69.2541 16.6706 69.4297 14.8155C69.4297 13.1108 68.9154 11.5817 67.8868 10.228C66.8582 8.89933 65.5913 8.0094 64.0861 7.55817ZM58.1027 2.06816C59.3571 1.7172 60.5989 1.54172 61.8282 1.54172C64.0861 1.54172 66.2938 2.15589 68.4513 3.38425C70.4332 4.51234 72.1141 6.1794 73.4939 8.38543C74.5475 10.2906 75.1371 12.4967 75.2625 15.0035C75.2625 16.8586 74.9113 18.5758 74.2089 20.1551C73.5064 21.7595 72.4527 23.2135 71.0478 24.517C68.514 26.9738 65.4157 28.2146 61.7529 28.2397C56.8107 28.2397 52.9848 26.0838 50.2754 21.772C49.2217 19.8919 48.5945 17.6107 48.3938 14.9283C48.3938 12.0204 49.2844 9.38817 51.0656 7.03173C52.8218 4.67528 55.1674 3.02076 58.1027 2.06816Z"
          fill="#094927"
        />
        <path
          d="M83.9177 4.58754C84.1435 4.43713 84.5324 4.36193 85.0843 4.36193C86.0125 4.36193 86.9533 4.54994 87.9066 4.92597C89.2363 5.42734 90.2649 6.27967 90.9924 7.48296C91.72 8.68625 92.0837 10.065 92.0837 11.6193C92.0837 12.8226 91.8579 14.076 91.4064 15.3796C90.9548 16.5578 90.2147 17.6608 89.1861 18.6886C88.1575 19.7415 87.1289 20.4058 86.1003 20.6816C85.147 20.9824 84.4195 21.1328 83.9177 21.1328C83.7672 21.1328 83.5414 21.1328 83.2404 21.1328C83.2655 19.7791 83.3156 17.0591 83.3909 12.973C83.4912 7.5331 83.6669 4.73796 83.9177 4.58754ZM76.4667 1.72973C76.5671 1.60439 77.5581 1.54172 79.4396 1.54172C80.0668 1.54172 81.0076 1.54172 82.262 1.54172C82.2369 2.54446 82.1742 4.57501 82.0738 7.63337C81.9484 11.3435 81.8856 16.1943 81.8856 22.1857C81.8856 23.9906 81.8856 26.7231 81.8856 30.3831C80.9574 30.3831 79.0884 30.3831 76.2786 30.3831C76.2786 28.0266 76.2786 23.3263 76.2786 16.282C76.2786 6.6557 76.3413 1.80494 76.4667 1.72973Z"
          fill="#094927"
        />
        <path
          d="M94.7179 16.5828C94.6928 14.3517 94.6803 9.90208 94.6803 3.23384C96.7625 3.23384 100.952 3.23384 107.249 3.23384C107.249 4.0611 107.249 5.7407 107.249 8.27262C106.145 8.27262 103.912 8.27262 100.551 8.27262C100.576 9.57618 100.601 12.1708 100.626 16.0564C104.239 16.0063 106.772 15.9937 108.228 16.0188C108.278 16.0689 108.315 16.3196 108.34 16.7709C108.366 17.2221 108.378 17.8488 108.378 18.651C108.378 19.1022 108.378 19.7665 108.378 20.6439C107.074 20.6439 104.464 20.6439 100.551 20.6439C100.551 21.3459 100.551 22.7246 100.551 24.7803C101.855 24.7803 104.464 24.7803 108.378 24.7803C108.378 25.6075 108.378 27.262 108.378 29.7438C107.625 29.794 106.108 29.8566 103.825 29.9318C101.366 29.982 99.5598 30.007 98.4058 30.007C98.1047 30.007 97.6532 30.007 97.051 30.007C96.6747 30.007 95.9347 29.982 94.8308 29.9318C94.8057 27.7258 94.7681 23.2761 94.7179 16.5828Z"
          fill="#094927"
        />
        <path
          d="M116.77 16.7333C116.92 16.7583 117.874 16.7959 119.63 16.8461C121.361 16.9213 122.264 16.9965 122.339 17.0717C122.49 17.1218 122.64 17.2096 122.791 17.3349C122.941 17.4602 123.104 17.6232 123.28 17.8237C123.531 18.1246 124.447 20.0047 126.027 23.4642C127.633 26.8986 128.436 28.8038 128.436 29.1798C128.31 29.2801 127.294 29.3678 125.387 29.443C123.506 29.5182 122.553 29.5182 122.527 29.443C121.097 26.134 119.178 21.8974 116.77 16.7333ZM109.808 0.639248C109.883 0.564042 110.485 0.526439 111.614 0.526439C111.865 0.526439 112.229 0.526439 112.706 0.526439C113.132 0.526439 114.01 0.564042 115.34 0.639248C115.34 0.965139 115.327 1.65452 115.302 2.7074C115.302 3.03329 115.302 3.86056 115.302 5.18919C115.277 6.54289 115.252 8.4481 115.227 10.9048C115.227 13.3615 115.227 15.4548 115.227 17.1845C115.227 19.942 115.214 24.0783 115.189 29.5934C114.261 29.5934 112.405 29.5934 109.62 29.5934C109.62 27.1868 109.62 22.3987 109.62 15.2291C109.62 5.62789 109.683 0.764591 109.808 0.639248ZM118.576 0.188014C119.429 0.0626714 120.307 0 121.21 0C123.518 0 125.4 0.26322 126.855 0.789659C129.715 1.89268 131.145 3.73521 131.145 6.31727C131.145 8.29769 130.242 10.2656 128.436 12.2209C127.457 13.3239 126.353 14.1637 125.124 14.7403C123.995 15.3169 122.578 15.6804 120.872 15.8308C119.166 15.9812 117.786 15.9311 116.732 15.6804C116.707 14.0259 116.682 11.5566 116.657 8.27262C116.657 7.94673 116.657 7.25734 116.657 6.20447C116.657 2.64473 116.72 0.802193 116.845 0.676851C116.996 0.526439 117.573 0.363494 118.576 0.188014Z"
          fill="#094927"
        />
        <path
          d="M140.327 6.6933C140.979 6.49275 141.732 6.39248 142.585 6.39248C144.266 6.39248 145.796 6.91892 147.176 7.9718C148.531 9.02468 149.471 10.4536 149.998 12.2585C150.575 14.1637 150.864 16.4951 150.864 19.2526C150.864 22.0353 150.563 25.2942 149.961 29.0294C149.76 30.3329 149.622 31.1602 149.547 31.5112C149.446 31.8371 149.308 32 149.133 32C149.108 32 149.083 32 149.057 32C149.007 31.9749 148.97 31.9624 148.945 31.9624C148.518 31.8621 147.59 31.6992 146.16 31.4736C144.855 31.2229 144.153 31.0223 144.053 30.8719C144.027 30.8218 144.015 30.7591 144.015 30.6839C144.015 30.6588 144.015 30.6212 144.015 30.5711C144.04 30.4959 144.053 30.4332 144.053 30.3831C144.078 30.2076 144.115 29.9569 144.165 29.631C141.055 29.631 138.96 29.5182 137.881 29.2926C136.777 29.067 136.188 28.9291 136.112 28.879C134.958 28.3525 134.168 27.7885 133.742 27.1868C132.763 25.9083 132.274 24.492 132.274 22.9377C132.274 21.8347 132.55 20.8194 133.102 19.8919C133.654 18.9894 134.444 18.275 135.473 17.7485C136.401 17.2973 137.454 17.0717 138.634 17.0717C140.34 17.0717 142.347 17.548 144.655 18.5006C144.805 18.5507 144.893 18.5758 144.918 18.5758C145.043 18.5758 145.106 18.3752 145.106 17.9741C145.106 17.8989 145.081 17.4226 145.031 16.5452C144.956 15.2166 144.73 14.1512 144.354 13.349C144.128 12.8978 143.915 12.6095 143.714 12.4841C143.513 12.3839 143.162 12.3087 142.66 12.2585C142.61 12.2585 142.547 12.2585 142.472 12.2585C141.268 12.2585 139.612 12.9354 137.505 14.2891C136.727 14.7403 136.326 14.9659 136.3 14.9659C134.469 12.8602 133.553 11.3937 133.553 10.5664C133.553 10.1904 134.381 9.53858 136.037 8.61105C137.668 7.68351 139.098 7.04426 140.327 6.6933Z"
          fill="#094927"
        />
        <path
          d="M156.96 10.416C156.985 9.8394 157.048 8.69879 157.148 6.99412C158.076 6.99412 159.933 6.99412 162.718 6.99412C162.667 8.02193 162.592 10.0525 162.492 13.0858C162.366 16.7959 162.304 20.6189 162.304 24.5546C162.304 25.7579 162.316 27.5629 162.341 29.9694C161.865 29.9694 160.911 29.9318 159.481 29.8566C159.005 29.8566 158.064 29.8316 156.659 29.7814C156.659 28.4528 156.672 25.7955 156.697 21.8096C156.747 16.3698 156.835 12.5719 156.96 10.416ZM152.407 0.488837C152.808 0.513905 153.648 0.576577 154.928 0.676851C156.784 0.777125 159.343 0.827262 162.605 0.827262C163.734 0.827262 165.44 0.827262 167.723 0.827262C167.723 1.7548 167.723 3.6224 167.723 6.43008C166.819 6.43008 164.988 6.43008 162.228 6.43008C158.39 6.43008 155.768 6.36741 154.363 6.24207C153.837 6.19193 153.059 6.14179 152.03 6.09166C152.03 5.61535 152.055 4.83823 152.106 3.76028C152.131 2.7074 152.231 1.61692 152.407 0.488837Z"
          fill="#094927"
        />
        <path
          d="M170.244 30.0447C170.244 27.0615 170.244 21.1077 170.244 12.1833C171.172 12.1833 173.041 12.1833 175.851 12.1833C175.851 13.6874 175.851 16.7082 175.851 21.2456C175.851 25.8833 175.863 28.7912 175.889 29.9694C175.813 30.0948 174.847 30.1575 172.991 30.1575C171.109 30.1575 170.194 30.1199 170.244 30.0447ZM171.147 2.85781C171.699 2.58206 172.301 2.44418 172.953 2.44418C174.433 2.44418 175.65 3.17117 176.604 4.62515C177.03 5.20172 177.243 5.84097 177.243 6.54289C177.243 7.59577 176.817 8.53584 175.964 9.3631C175.111 10.2154 174.107 10.6416 172.953 10.6416C172.652 10.6416 172.339 10.604 172.013 10.5288C171.059 10.2781 170.307 9.77673 169.755 9.02468C169.203 8.24755 168.927 7.39522 168.927 6.46768C168.927 4.78809 169.667 3.5848 171.147 2.85781Z"
          fill="#094927"
        />
        <path
          d="M193.011 8.76146C193.036 8.66118 193.099 8.42303 193.199 8.047C193.625 8.07207 194.516 8.12221 195.871 8.19741C197.777 8.24755 198.731 8.33529 198.731 8.46063C198.731 8.83666 198.317 11.4689 197.489 16.3572C196.636 21.2707 196.122 24.0282 195.946 24.6298C195.921 24.8304 195.858 25.1061 195.758 25.4571C192.396 25.3819 190.653 25.3318 190.527 25.3067C190.402 25.3067 190.339 25.2691 190.339 25.1939C190.339 24.9933 190.74 22.4489 191.543 17.5605C192.346 12.6722 192.835 9.73913 193.011 8.76146ZM178.41 7.21974C178.435 7.1696 178.799 7.0568 179.501 6.88132C180.204 6.68077 180.956 6.48022 181.759 6.27967C182.612 6.07912 183.189 5.97885 183.49 5.97885C183.59 5.97885 183.653 5.99138 183.678 6.01645C183.829 6.11673 184.13 7.14454 184.581 9.09988C185.234 11.5315 186.149 14.9033 187.328 19.215C187.78 20.8194 188.445 23.2385 189.323 26.4724C190.377 26.4724 192.496 26.4724 195.683 26.4724C195.658 26.5977 195.607 26.886 195.532 27.3372C195.507 27.5127 195.444 27.8762 195.344 28.4277C195.244 28.9542 195.181 29.3177 195.156 29.5182C195.106 29.819 195.043 30.2452 194.968 30.7967C193.237 30.7967 189.787 30.7967 184.619 30.7967C184.318 29.7438 183.728 27.6381 182.85 24.4794C179.89 13.8002 178.41 8.07207 178.41 7.29495C178.41 7.24481 178.41 7.21974 178.41 7.21974Z"
          fill="#094927"
        />
        <path
          d="M201.214 30.0447C201.214 27.0615 201.214 21.1077 201.214 12.1833C202.143 12.1833 204.012 12.1833 206.821 12.1833C206.821 13.6874 206.821 16.7082 206.821 21.2456C206.821 25.8833 206.834 28.7912 206.859 29.9694C206.784 30.0948 205.818 30.1575 203.961 30.1575C202.08 30.1575 201.164 30.1199 201.214 30.0447ZM202.118 2.85781C202.669 2.58206 203.272 2.44418 203.924 2.44418C205.404 2.44418 206.621 3.17117 207.574 4.62515C208.001 5.20172 208.214 5.84097 208.214 6.54289C208.214 7.59577 207.787 8.53584 206.934 9.3631C206.081 10.2154 205.078 10.6416 203.924 10.6416C203.623 10.6416 203.309 10.604 202.983 10.5288C202.03 10.2781 201.277 9.77673 200.725 9.02468C200.173 8.24755 199.897 7.39522 199.897 6.46768C199.897 4.78809 200.637 3.5848 202.118 2.85781Z"
          fill="#094927"
        />
        <path
          d="M213.746 4.54994C213.746 4.44967 214.034 4.39953 214.611 4.39953C215.063 4.39953 215.715 4.43713 216.568 4.51234C218.45 4.63768 219.39 4.70035 219.39 4.70035C219.415 4.72542 219.428 4.78809 219.428 4.88837C219.428 4.96357 219.353 5.49001 219.202 6.46768C218.951 8.09714 218.826 8.94947 218.826 9.02468C219.152 9.07481 219.917 9.09988 221.121 9.09988C222.326 9.09988 222.99 9.09988 223.116 9.09988C224.044 9.09988 225.461 9.09988 227.368 9.09988C227.343 9.7266 227.305 10.6792 227.255 11.9577C227.23 13.2362 227.205 14.1888 227.18 14.8155C226.252 14.8155 224.859 14.8155 223.003 14.8155C222.426 14.8155 221.736 14.828 220.933 14.8531C220.105 14.8531 219.478 14.8656 219.052 14.8907C218.675 14.8907 218.45 14.8907 218.374 14.8907C218.224 16.5202 218.161 17.9741 218.186 19.2526C218.236 20.7317 218.412 21.9851 218.713 23.0129C219.089 24.2413 219.666 24.9307 220.444 25.0811C221.999 25.0811 224.032 24.0909 226.54 22.1105C226.616 22.1355 226.691 22.1481 226.766 22.1481C226.891 22.2985 227.142 22.6118 227.519 23.0881C227.87 23.5644 228.648 24.8179 229.852 26.8484C229.726 26.9487 229.526 27.1116 229.25 27.3372C227.995 28.3149 226.616 29.1297 225.11 29.7814C223.58 30.4332 222.075 30.7591 220.595 30.7591C217.76 30.734 215.59 29.4806 214.084 26.9988C213.783 26.4724 213.507 25.8707 213.256 25.1939C213.031 24.517 212.842 23.7775 212.692 22.9753C212.541 22.1731 212.454 21.0826 212.429 19.7039C212.403 18.3251 212.429 16.658 212.504 14.7027C211.826 14.7027 210.785 14.7027 209.38 14.7027C209.38 13.7752 209.38 11.9076 209.38 9.09988C211.663 9.09988 212.83 9.09988 212.88 9.09988C212.955 9.09988 213.031 9.03721 213.106 8.91187C213.206 8.78653 213.282 8.31022 213.332 7.48296C213.407 6.6557 213.545 5.67803 213.746 4.54994Z"
          fill="#094927"
        />
        <path
          d="M246.824 7.55817C246.422 7.43282 246.021 7.34508 245.619 7.29495C245.243 7.24481 244.854 7.21974 244.453 7.21974C242.872 7.21974 241.367 7.69604 239.937 8.64865C237.83 10.1277 236.776 12.2209 236.776 14.9283C236.776 16.5076 237.228 17.9616 238.131 19.2902C239.059 20.5938 240.251 21.5339 241.706 22.1105C242.383 22.3611 243.299 22.4865 244.453 22.4865C247.288 22.1857 249.345 21.1453 250.624 19.3655C251.477 18.1872 251.992 16.6706 252.167 14.8155C252.167 13.1108 251.653 11.5817 250.624 10.228C249.596 8.89933 248.329 8.0094 246.824 7.55817ZM240.84 2.06816C242.095 1.7172 243.336 1.54172 244.566 1.54172C246.824 1.54172 249.031 2.15589 251.189 3.38425C253.171 4.51234 254.852 6.1794 256.231 8.38543C257.285 10.2906 257.875 12.4967 258 15.0035C258 16.8586 257.649 18.5758 256.946 20.1551C256.244 21.7595 255.19 23.2135 253.785 24.517C251.251 26.9738 248.153 28.2146 244.49 28.2397C239.548 28.2397 235.722 26.0838 233.013 21.772C231.959 19.8919 231.332 17.6107 231.131 14.9283C231.131 12.0204 232.022 9.38817 233.803 7.03173C235.559 4.67528 237.905 3.02076 240.84 2.06816Z"
          fill="#094927"
        />
      </svg>
    </NavLink>
  );
};
export default LogoFooter;
