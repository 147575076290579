import React, { useContext, useEffect, useState } from "react";
import BoardCell from "./BoardCell";
import { GameContext } from "../../../store/GameContext";
import { useWindowSize } from "@react-hook/window-size";
import BoardAction from "./BoardAction";
import { BsPauseBtn } from "react-icons/bs";

const GameBoard = () => {
  const { board, game, start, togglePause, size } = useContext(GameContext);
  const [windowWidth, windowHeight] = useWindowSize();
  const [cellSize, setCellSize] = useState(null);

  useEffect(() => {
    if (!size) return;

    const isXsScreen = windowWidth < 639;
    const isSmScreen = windowWidth < 768;

    const maxBoardX = windowWidth - (isXsScreen ? 50 : isSmScreen ? 100 : 220);
    const maxBoardY = windowHeight - (isSmScreen ? 160 : 60);

    const boardSize = windowWidth >= 768 ? 650 : Math.min(maxBoardX, maxBoardY);
    const maxCellX = Math.floor(boardSize / (size?.x || 1));
    const maxCellY = Math.floor(boardSize / (size?.y || 1));

    setCellSize(Math.min(maxCellX, maxCellY));
  }, [size, windowWidth, windowHeight]);

  if (!size || !board) return null;

  return (
    <div className="flex items-center justify-center h-full font-inter ">
      <div
        className="relative flex items-end"
        style={{
          width: size.x * cellSize,
          height: size.y * cellSize,
          maxWidth: windowWidth >= 768 ? 650 : "100%",
          maxHeight: windowWidth >= 768 ? 650 : "100%",
        }}>
        {!!board &&
          !game.pauseTime &&
          board.map((cell) => (
            <BoardCell key={cell.id} {...cell} cellSize={cellSize} />
          ))}
        {!game.startTime && <BoardAction onClick={start}>Jugar</BoardAction>}
        {!!game.pauseTime && (
          <BoardAction onClick={togglePause}>
            Juego pausado
            <BsPauseBtn className="text-5xl" />
          </BoardAction>
        )}
      </div>
    </div>
  );
};

export default GameBoard;
