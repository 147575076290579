import GameInfo from "./GameInfo";
import { FaRegHandPointer } from "react-icons/fa";
import ControlButton from "./ControlButton";
import { useContext, useRef } from "react";
import { GameContext } from "../../../store/GameContext";
import { useNavigate } from "react-router-dom";
//import { FaFlagCheckered } from "react-icons/fa";
import GameClock from "./GameClock";
import { sendPlayerData } from "../../../data/dbService";
import { useSelector } from "react-redux";

/**
 * Renders the game controls, either on the right or on the bottom of the puzzle board
 * this includes the game information (moves/clock) and the action buttons
 * @returns {JSX.Element}
 * @constructor
 */

const GameControls = () => {
  /**
   * @type {import('../../../store/GameContext').GameContextType}
   */
  const { game, start, togglePause, pickNewImageRnd, puzzleImage } =
    useContext(GameContext);

  /**
   * @type {NavigateFunction} navigate instance to go to the "change size" screen
   */
  const navigate = useNavigate();
  const prevGameWon = useRef(false);
  const name = useSelector((state) => state.nombre);
  const department = useSelector((state) => state.departamento);
  const school = useSelector((state) => state.escuela);
  const grade = useSelector((state) => state.grado);

  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const sendPlayerDataOnWin = () => {
    const { moves, gameWon, endTime, startTime } = game;
    if (gameWon && !prevGameWon.current) {
      let puzzleTime = null;

      if (endTime) {
        const elapsedTimeInSeconds = Math.floor((endTime - startTime) / 1000);
        puzzleTime = formatTime(elapsedTimeInSeconds);
      }

      const playerData = {
        department,
        selectedSchool: school,
        grade,
        name,
        puzzleScore: moves.length,
        puzzleTime: puzzleTime,
      };

      sendPlayerData(playerData);
      prevGameWon.current = true;
    }
  };

  if (game?.gameWon) {
    sendPlayerDataOnWin();
  } else {
    prevGameWon.current = false;
  }
  const showImage = game?.moves?.length >= 30;
  return (
    <div className="flex flex-col justify-between sm:gap-20  gap-4">
      <div className="flex flex-row md:flex-col gap-4 justify-between">
        <GameInfo
          label="Movimientos"
          icon={<FaRegHandPointer className="w-5" />}
          z>
          {game?.moves?.length ?? 0}
        </GameInfo>
        <GameClock />
        {game?.gameWon && navigate("/puzzle/ranking")}
      </div>
      {/*Muestra la img del puzzle en mobile*/}
      <div className="flex items-center justify-center my-4">
        {showImage && (
          <div className="sm:w-full w-40 block sm:hidden">
            <span className="text-white text-base font-normal font-inter">
              ¡PISTA!
            </span>
            <img src={puzzleImage} alt="puzzle" className="w-full" />
          </div>
        )}
      </div>
      <div className="flex-col gap-2 items-center sm:flex hidden">
        {!game?.startTime && [
          <ControlButton onClick={start} key="startGame">
            Empezar
          </ControlButton>,
          <ControlButton onClick={pickNewImageRnd} key="changeImage">
            Cambiar Puzzle
          </ControlButton>,
        ]}
        {!!game?.startTime && !game?.gameWon && (
          <ControlButton onClick={togglePause}>
            {game.pauseTime ? "Continuar" : "Pausar"}
          </ControlButton>
        )}
        {!!game?.startTime && (
          <ControlButton onClick={pickNewImageRnd}>
            Reiniciar puzzle
          </ControlButton>
        )}
        {/* <ControlButton onClick={() => navigate("/puzzle/select-size")}>
          Dificultad
        </ControlButton> */}
        <ControlButton onClick={() => navigate("/seleccion-juegos")}>
          Volver al inicio
        </ControlButton>
      </div>
      {/* Mobile */}
      <div className="flex-col items-center sm:hidden flex px-4">
        {!game?.startTime && [
          <button
            onClick={start}
            key="startGame"
            className="py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-full h-14">
            Empezar
          </button>,
        ]}
      </div>
      <div className="flex-col items-center sm:hidden flex px-4">
        {!!game?.startTime && !game?.gameWon && (
          <button
            onClick={togglePause}
            className="py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-full h-14">
            {game.pauseTime ? "Continuar" : "Pausar"}
          </button>
        )}
      </div>
      <div className="flex-row gap-2 items-center sm:hidden flex px-4">
        {!game?.startTime && [
          <button
            onClick={pickNewImageRnd}
            key="changeImage"
            className="py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-full h-14">
            Cambiar Puzzle
          </button>,
        ]}

        {!!game?.startTime && (
          <button
            onClick={pickNewImageRnd}
            className="py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-full h-14">
            Reiniciar puzzle
          </button>
        )}

        <button
          onClick={() => navigate("/seleccion-juegos")}
          className="py-1 md:p-2 rounded bg-green-900  text-white  text-xl w-full h-14">
          Volver al inicio
        </button>
      </div>
    </div>
  );
};

export default GameControls;
