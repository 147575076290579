import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div className="font-inter flex flex-col items-center min-h-screen w-full">
      <img src="/caf-hero.webp" alt="hero" className="w-full h-auto" />

      <div className="lg:max-w-3xl max-w-3xl flex-col justify-center items-center flex">
        <div className="self-stretch sm:text-center text-left px-4 sm:px-0 text-lime-600 sm:text-6xl text-4xl font-bold font-inter tracking-wider mb-10 mt-24">
          ¡Bienvenidos a Cooperativito!
        </div>
        <div className="lg:max-w-3xl max-w-3xl sm:text-center text-left mb-24">
          <p className="text-neutral-700 text-xl font-normal font-inter tracking-tight px-4 sm:px-0">
            Este es un proyecto que tiene como objetivo facilitar el acceso de
            información sobre el cooperativismo agrario a niños y niñas de 4º,
            5º y 6º, docentes y familias.
            <br />
            <br />A través de una herramienta virtual que incluye dos recursos
            lúdicos con contenidos supervisados por adultos responsables,
            buscamos brindar una semillita destinada a germinar, multiplicarse y
            florecer. La semillita del conocimiento y la reflexión sobre una
            herramienta maravillosa para el mundo como lo es el cooperativismo,
            y en especial sobre su importancia en el sector agrario uruguayo.
            <br />
            <br />
            Las cooperativas agrarias permiten que miles de familias de
            productores permanezcan en el lugar que aman: el campo,
            contribuyendo al desarrollo de sus comunidades, la seguridad
            alimentaria, la generación de empleo de calidad, la conservación de
            la biodiversidad y las tradiciones culturales.
            <br />
            <br />
            Es una iniciativa de las cooperativas agrarias COLAVECO y EL FOGÓN,
            y de Cooperativas Agrarias Federadas.
            <br />
            <br />
          </p>
          <p className="text-neutral-700 text-xl font-bold font-inter px-4 sm:px-0">
            ¡Con Cooperativito, vamos a descubrir juntos el trabajo que
            realizan!
          </p>
        </div>
      </div>
      <div className="items-center flex w-full">
        <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://www.youtube.com/embed/ElVoid5de28?si=iWNmsk2RnbVmDtKQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen></iframe>
        </div>
      </div>
      <div className="max-w-3xl flex-col justify-center items-center flex">
        <div className="self-stretch sm:text-center text-left px-4 sm:px-0 text-lime-600 sm:text-6xl text-4xl font-bold font-inter tracking-wider mb-10 mt-24">
          ¿Cómo jugar?
        </div>
        <p className="text-neutral-700 text-xl font-normal font-inter tracking-tight px-4 sm:px-0">
          Con el objetivo de fomentar el aprendizaje colaborativo, recomendamos
          que esta herramienta lúdica se utilice en el aula con la supervisión
          de un docente y que se juegue en equipos.
          <br />
          <br />
          Para comenzar a jugar, tendrán que ingresar a la sección “JUEGOS” y
          elegir una de las dos opciones disponibles: PUZZLE o TRIVIA. Una vez
          dentro, encontrarán las instrucciones y el botón de inicio del juego.
          <br />
          <br />
          Antes de empezar, deberán organizarse en equipos, poner el nombre del
          equipo y seleccionar un avatar, su escuela y departamento. Este juego
          estará disponible durante todo el año lectivo y, en la pestaña
          “Ranking” encontrarán el ranking por participación de todas las
          escuelas participantes. Ahora sí, ¡a jugar!
        </p>
        <div className="w-screen px-4 sm:px-0 sm:flex sm:items-center sm:justify-center">
          <NavLink to="/juegos">
            <button className="sm:w-96 w-full h-14 bg-green-900 rounded-md text-white text-xl mt-12">
              Jugar
            </button>
          </NavLink>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
        <div className="flex flex-col md:flex-row justify-center items-center gap-10 my-24">
          <div className="flex flex-row items-center sm:gap-10 gap-4">
            <span className="text-center text-lime-600 sm:text-xl text-xs font-bold font-inter capitalize tracking-wide">
              presentan:
            </span>

            <div className="flex flex-row gap-4 md:gap-10 items-center">
              <a
                href="https://caf.org.uy"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-20 w-14"
                  src="/caf.png"
                  alt="logo"
                />
              </a>
              <a
                href="https://www.colaveco.com.uy/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-32 h-auto w-20"
                  src="/colaveco.png"
                  alt="logo"
                />
              </a>
              <a
                href="https://elfogoncal.com.uy/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="mx-auto object-contain sm:w-28 w-20"
                  src="/fogon.png"
                  alt="logo"
                />
              </a>
            </div>
          </div>

          <div className="flex flex-row items-center gap-10 mt-10 md:mt-0">
            <span className="text-center text-lime-600 sm:text-xl text-xs font-bold font-inter capitalize tracking-wide">
              apoya:
            </span>

            <a
              href="https://www.inacoop.org.uy/"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/inacoop.svg" alt="inacoop" className="sm:w-60 w-32" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
