import React, { useEffect, useState } from "react";
import { Results } from "./Results";

const Question = ({
  filteredQuestion,
  questionsFiltered,
  indexQuestion,
  setIndexQuestion,
  setActiveQuiz,
}) => {
  const [score, setScore] = useState(0);
  const [selectAnswerIndex, setSelectAnswerIndex] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [answersRandom, setAnswersRandom] = useState([]);
  const [activeResults, setActiveResults] = useState(false);

  useEffect(() => {
    const answers = [
      ...filteredQuestion.incorrect_answers,
      filteredQuestion.correct_answer,
    ];

    setAnswersRandom(answers.sort(() => Math.random() - 0.5));
  }, [filteredQuestion]);

  const checkAnswer = (answerText, index) => {
    if (answerText === filteredQuestion.correct_answer) {
      setScore(score + 1);
    }
    setSelectAnswerIndex(index);
    setAnswered(true);
  };

  const onNextQuestion = () => {
    setIndexQuestion(indexQuestion + 1);
    setSelectAnswerIndex(null);
    setAnswered(false);
  };

  const onReset = () => {
    setScore(0);
    setSelectAnswerIndex(null);
    setAnswered(false);
    setActiveResults(false); // Reset the state of activeResults
    setIndexQuestion(0);
  };

  return (
    <>
      {activeResults ? (
        <Results
          questionsFiltered={questionsFiltered}
          score={score}
          onReset={onReset}
        />
      ) : (
        <div className="bg-white flex flex-col justify-between sm:w-2/4 w-full sm:p-10 p-0 rounded-3xl text-green-900 h-full gap-7 my-16 sm:px-24 px-4">
          <div className="flex justify-between sm:mt-12 mt-10">
            <span className="text-xl font-bold text-lime-600">
              {/* Current question number and total number of questions */}
              {indexQuestion + 1} / {questionsFiltered.length}
            </span>
            <div>
              {/* <span className="font-semibold">Dificultad: </span> */}
              <span className="font-bold text-lime-600">
                {/* Question difficulty */}
                {filteredQuestion.category}
              </span>
            </div>
          </div>

          <div>
            <h1 className="font-bold mt-6">{filteredQuestion.question}</h1>
          </div>

          {/* Answers go here */}
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-5 mb-6">
            {/* Map over an array of correct and incorrect answers */}
            {answersRandom.map((answer, index) => (
              <button
                className={`border p-5 rounded-lg flex justify-center items-center hover:scale-105 border-lime-600 text-green-900 ${
                  selectAnswerIndex !== null && index === selectAnswerIndex
                    ? answer === filteredQuestion.correct_answer
                      ? "bg-emerald-200"
                      : "bg-rose-300"
                    : ""
                }`}
                key={answer}
                onClick={() => checkAnswer(answer, index)}
                disabled={answered && selectAnswerIndex !== index}>
                <p className="font-medium text-center text-sm">{answer}</p>
              </button>
            ))}
          </div>

          {/* Show the result message after a selection */}
          {selectAnswerIndex !== null && (
            <p className="text-center text-green-900 text-sm font-normal font-inter">
              {answersRandom[selectAnswerIndex] ===
              filteredQuestion.correct_answer
                ? "La respuesta es correcta, pasá a la siguiente pregunta"
                : "La respuesta no es correcta, pasá a la siguiente pregunta"}
            </p>
          )}

          {/* Conditional to display the next question button or the finish button */}

          {indexQuestion + 1 === questionsFiltered.length ? (
            <button
              className="border-2 text-white bg-green-900 rounded-md px-5 py-2 hover:scale-105 font-medium mb-16"
              onClick={() => {
                setAnswered(false);
                setActiveResults(true);
              }}>
              Finalizar
            </button>
          ) : (
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5 mb-16">
              <button
                className="border px-5 sm:py-2 py-5 rounded-lg font-bold transition-all bg-lime-600 text-white hover:scale-105 font-inter"
                onClick={onReset}>
                Reiniciar la trivia
              </button>
              <button
                className="text-white bg-green-900 rounded-md px-5 sm:py-2 py-5 hover:scale-105 font-medium "
                onClick={onNextQuestion}>
                Siguiente pregunta
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default Question;
