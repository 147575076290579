import React from "react";
import LogoFooter from "../components/LogoFooter";

// import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bottom-0 max-w-screen text-center mx-auto px-32">
      <div className="w-full max-w-screen p-4 md:py-8">
        <div className="flex items-center justify-center">
          <LogoFooter />
        </div>
      </div>
    </footer>
  );
}
