import React, { useState } from "react";

const Contacto = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://formspree.io/f/xvgpzykq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setMessageSent(true);
      setTimeout(() => {
        setMessageSent(false);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      }, 3000);
    } else {
      alert("Error enviando mensaje");
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto my-12 flex flex-col md:flex-row justify-center items-center">
      <form
        className="space-y-6 flex-col max-w-xl w-full px-4 sm:px-0"
        onSubmit={handleSubmit}>
        <div className="text-lime-600 text-5xl font-bold font-inter capitalize tracking-tight mb-4">
          Contacto
        </div>
        <div className="text-neutral-500 text-base font-normal font-inter tracking-tight mb-8">
          Hacenos llegar tus preguntas o comentarios
        </div>
        <label
          htmlFor="first_name"
          className="block text-sm font-normal text-lime-600">
          Nombre
        </label>
        <input
          id="first_name"
          name="firstName"
          type="text"
          autoComplete="given-name"
          required
          onChange={handleChange}
          value={formData.firstName}
          className="focus:outline-green-900 w-full text-green-900 text-base font-medium font-inter tracking-tight border border-lime-600 rounded-[5px] py-2.5 pl-1"
        />

        <label
          htmlFor="last_name"
          className="block text-sm font-normal text-lime-600">
          Apellido
        </label>
        <input
          id="last_name"
          name="lastName"
          type="text"
          autoComplete="family-name"
          required
          onChange={handleChange}
          value={formData.lastName}
          className="focus:outline-green-900 w-full text-green-900 text-base font-medium font-inter tracking-tight border border-lime-600 rounded-[5px] py-2.5 gap-2.5 pl-1"
        />

        <label
          htmlFor="email"
          className="block text-sm font-normal text-lime-600">
          Correo Electrónico
        </label>
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          onChange={handleChange}
          value={formData.email}
          className=" focus:outline-green-900 w-full text-green-900 text-base font-medium font-inter tracking-tight border border-lime-600 rounded-[5px] py-2.5 gap-2.5 pl-1"
        />

        <label
          htmlFor="message"
          className="block text-sm font-normal text-lime-600">
          Mensaje
        </label>
        <textarea
          id="message"
          name="message"
          rows="4"
          onChange={handleChange}
          value={formData.message}
          className="w-full text-green-900 text-base font-medium font-inter tracking-tight border border-lime-600 rounded-[5px] py-2.5 gap-2.5 focus:outline-green-900 pl-1"></textarea>

        <div className="flex items-center mx-auto">
          <button
            type="submit"
            className="h-10 w-40 bg-green-900 rounded-md font-inter text-white mb-10">
            Enviar Mensaje
          </button>
        </div>
        {messageSent && (
          <div className="text-green-900 font-inter mt-4">
            Mensaje enviado exitosamente
          </div>
        )}
      </form>
      <div className="max-w-3/4 md:max-w-xl flex items-center justify-center md:ml-32">
        <div className="flex-col flex items-center gap-12">
          <div className="max-3/4 text-lime-600 sm:text-2xl text-sm font-bold font-inter tracking-tight ml-4">
            Los invitamos a conocer más sobre las organizaciones responsables de
            esta iniciativa:
          </div>
          <div className="flex flex-row gap-4 md:gap-10 items-center">
            <a
              href="https://caf.org.uy"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="mx-auto object-contain sm:w-20 w-14"
                src="/caf.png"
                alt="logo"
              />
            </a>
            <a
              href="https://www.colaveco.com.uy/"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="mx-auto object-contain sm:w-32 h-auto w-20"
                src="/colaveco.png"
                alt="logo"
              />
            </a>
            <a
              href="https://elfogoncal.com.uy/"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="mx-auto object-contain sm:w-28 w-20"
                src="/fogon.png"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
