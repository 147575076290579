export const departments = [
  "Artigas",
  "Canelones",
  "Cerro Largo",
  "Colonia",
  "Durazno",
  "Flores",
  "Florida",
  "Lavalleja",
  "Maldonado",
  "Montevideo",
  "Paysandú",
  "Río Negro",
  "Rivera",
  "Rocha",
  "Salto",
  "San José",
  "Soriano",
  "Tacuarembó",
  "Treinta y Tres",
];

export const schoolsByDepartment = {
  Artigas: [
    "Escuela N°1 - ARTIGAS",
    "Escuela N°2 - ESPAÑA",
    "Escuela N°3 - MARIA ORTICOCHEA",
    "Escuela N°4",
    "Escuela N°5 - CLEMENTE ESTABLE",
    "Escuela N°6",
    "Escuela N°7",
    "Escuela N°8 - BRIG.GRAL.JUAN A.LAVALLEJA",
    "Escuela N°9",
    "Escuela N°10",
    "Escuela N°11",
    "Escuela N°13",
    "Escuela N°14",
    "Escuela N°15",
    "Escuela N°16",
    "Escuela N°17",
    "Escuela N°19 - REPUBLICA FEDERATIVA DE BRASIL",
    "Escuela N°20",
    "Escuela N°21",
    "Escuela N°23 - CRISTOBAL COLON",
    "Escuela N°24",
    "Escuela N°25 - ING.EZEQUIEL DIMAS SILVA",
    "Escuela N°26 - a",
    "Escuela N°27",
    "Escuela N°28",
    "Escuela N°29",
    "Escuela N°30",
    "Escuela N°31",
    "Escuela N°32",
    "Escuela N°33",
    "Escuela N°34",
    "Escuela N°35",
    "Escuela N°36 - DORSOLINA BORBA",
    "Escuela N°37 - BARON DE RIO BRANCO",
    "Escuela N°38",
    "Escuela N°39",
    "Escuela N°40 - AGUSTIN FERREIRO",
    "Escuela N°41",
    "Escuela N°42",
    "Escuela N°43 - JOSE PEDRO VARELA",
    "Escuela N°44",
    "Escuela N°45",
    "Escuela N°46",
    "Escuela N°47",
    "Escuela N°48",
    "Escuela N°49 - DIONISIO DIAZ",
    "Escuela N°50",
    "Escuela N°51",
    "Escuela N°52",
    "Escuela N°54 - ENEIDA TEXEIRA DE BASALDUA",
    "Escuela N°56 - ELADIO F.DIESTE",
    "Escuela N°60",
    "Escuela N°61",
    "Escuela N°63",
    "Escuela N°64",
    "Escuela N°65",
    "Escuela N°66",
    "Escuela N°67",
    "Escuela N°68 - ITALIA",
    "Escuela N°70 - DANIEL FERNANDEZ CRESPO",
    "Escuela N°71",
    "Escuela N°72 - ENRIQUETA COMPTE Y RIQUE",
    "Escuela N°73 - PAUL HARRIS",
    "Escuela N°74",
    "Escuela N°75",
    "Escuela N°76",
    "Escuela N°77 - DR.BALTASAR BRUM",
    "Escuela N°78",
    "Escuela N°79 - CNEL.LORENZO LATORRE",
    "Escuela N°80 - GERARDO GRASSO",
    "Escuela N°81",
    "Escuela N°83",
    "Escuela N°84 - GERTRUDIS GASTESI",
    "Escuela N°86",
    "Escuela N°89",
    "Escuela N°90",
    "Escuela N°92",
    "Escuela N°93",
    "Escuela N°94",
  ],
  Canelones: [
    "Escuela Nº1 - TIMOTEO APARICIO",
    "Escuela Nº3 - GRAN BRETAÑA",
    "Escuela Nº4",
    "Escuela Nº5 - REPUBLICA FRANCESA",
    "Escuela Nº6 - HIPOLITO CABRERA",
    "Escuela Nº7",
    "Escuela Nº8",
    "Escuela Nº9 - MTRO. GREGORIO MIGUES VIEYTE",
    "Escuela Nº10 - FRANCISCO DE ASSIS MACHIN",
    "Escuela Nº11",
    "Escuela Nº12 - FRANCISCO JAVIER CABRERA",
    "Escuela Nº13 - ROSA FOURMENT DE CASSINA",
    "Escuela Nº14 - DOMINGO PICCARDO",
    "Escuela Nº15",
    "Escuela Nº16 - FRANCISCA A. PASCUAL DE ARTIGAS",
    "Escuela Nº17",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22 - LIBERTADOR SIMON BOLIVAR",
    "Escuela Nº23 - FUND. BIANCHI ARDOINO-DANERO",
    "Escuela Nº24",
    "Escuela Nº25 - EUSEBIO FRANCISCO PEREZ",
    "Escuela Nº26 - CANADA",
    "Escuela Nº27 - JOSE PERCOVICH",
    "Escuela Nº28 - DEMETRIO AÑON GERIBON",
    "Escuela Nº29 - NILDA ELENA SOLARI DE SANTORO",
    "Escuela Nº30",
    "Escuela Nº31 - GUYUNUSA",
    "Escuela Nº32 - JUAN FRANCISCO DELPIANO",
    "Escuela Nº33 - ROSA FERRARI DE RAMOS",
    "Escuela Nº34 - DIONISIO DIAZ",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº40 - VICENTE PARIANI",
    "Escuela Nº41 - ALFREDO ZITARROSA",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44 - MA. ELENA MARINO DE IGLESIAS",
    "Escuela Nº45 - MAESTRA PRAXEDES PINO",
    "Escuela Nº47 - MARIA LUISA PEREIRA",
    "Escuela Nº48",
    "Escuela Nº50 - GABINA ECILDA VAZQUEZ",
    "Escuela Nº51 - ANA FRANK",
    "Escuela Nº52",
    "Escuela Nº53 - CASILDO LARRAÑAGA",
    "Escuela Nº54 - CNEL. ANDRES GUACARARI ANDRESITO",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº57 - MTRA. ELISA SAENZ RIVERO",
    "Escuela Nº58 - J.J.DA SILVA XAVIER-TIRADENTES",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61 - LORENZO MOZZONE",
    "Escuela Nº62 - BLANCA ROSA GUARNERIO",
    "Escuela Nº63",
    "Escuela Nº64 - JUAN CAFASSO",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº67 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº68",
    "Escuela Nº69 - FRANKLIN CAPPI",
    "Escuela Nº71",
    "Escuela Nº72 - MARIA ESTER LABARRERE",
    "Escuela Nº73 - JOSE MALACRIDA",
    "Escuela Nº74 - FELIPE ANDRES CURBELO",
    "Escuela Nº75",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82 - MARIA CLOTILDE NUÑEZ",
    "Escuela Nº83",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88 - ALFREDO B. NOBEL",
    "Escuela Nº89",
    "Escuela Nº90 - LUIS EDUARDO ALONSO",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº93 - 19 DE JUNIO",
    "Escuela Nº94",
    "Escuela Nº95",
    "Escuela Nº96 - REZCALA NEFFA",
    "Escuela Nº98",
    "Escuela Nº99",
    "Escuela Nº100",
    "Escuela Nº101 - JOSE PEDRO VARELA",
    "Escuela Nº102 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº104 - LETICIA VOLPE",
    "Escuela Nº105",
    "Escuela Nº106 - ARTIGAS",
    "Escuela Nº107 - JOSE BELLONI",
    "Escuela Nº108",
    "Escuela Nº109 - GENERAL JOSE ARTIGAS",
    "Escuela Nº110 - JOAQUIN SUAREZ",
    "Escuela Nº111 - COLECTIVIDAD ITALIANA",
    "Escuela Nº112",
    "Escuela Nº113 - EUGENIO M. MIGUES",
    "Escuela Nº114 - CARLOS VON METZEN VICENTI",
    "Escuela Nº116 - REPUBLICA ARGENTINA",
    "Escuela Nº117 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº118 - JUAN OMAR CALANDRIA",
    "Escuela Nº119 - DAMASO ANTONIO LARRAÑAGA",
    "Escuela Nº120",
    "Escuela Nº121 - MAESTRO AGUSTIN FERREIRO",
    "Escuela Nº122 - BONIFACIO ALCAIN",
    "Escuela Nº123",
    "Escuela Nº124",
    "Escuela Nº125 - LUISA BLANCO ACEVEDO DE SOCA",
    "Escuela Nº126",
    "Escuela Nº127 - REPUBLICA CHECA",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº130",
    "Escuela Nº132",
    "Escuela Nº133 - CONFRATERNIDAD URUGUAYO-ARGENTINA",
    "Escuela Nº134",
    "Escuela Nº136",
    "Escuela Nº137",
    "Escuela Nº138 - ISLAS CANARIAS",
    "Escuela Nº139",
    "Escuela Nº140 - DR. MATEO LEGNANI",
    "Escuela Nº141 - AUSTRALIA",
    "Escuela Nº142",
    "Escuela Nº143",
    "Escuela Nº144",
    "Escuela Nº145",
    "Escuela Nº146 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº147",
    "Escuela Nº148 - FUNDACION TAPIE PIÑEYRO",
    "Escuela Nº149 - ANSINA",
    "Escuela Nº150",
    "Escuela Nº151",
    "Escuela Nº153 - MANUEL ORIBE",
    "Escuela Nº154",
    "Escuela Nº155 - REPUBLICA FEDERAL DE ALEMANIA",
    "Escuela Nº156 - PROFESOR CLEMENTE ESTABLE",
    "Escuela Nº157 - JOSE ENRIQUE RODO",
    "Escuela Nº159",
    "Escuela Nº160 - MTRO.LUIS ARBENOIZ GAMBARDELLA",
    "Escuela Nº162",
    "Escuela Nº163 - JAPON",
    "Escuela Nº165",
    "Escuela Nº166 - EMILIO ROMERO",
    "Escuela Nº167 - LIBERTADOR SIMON BOLIVAR",
    "Escuela Nº168",
    "Escuela Nº169",
    "Escuela Nº170",
    "Escuela Nº171 - DINAMARCA",
    "Escuela Nº172 - JAVIER DE VIANA",
    "Escuela Nº173",
    "Escuela Nº174 - NESTOR MONTELONGO Y FRANCISCA DUARTE",
    "Escuela Nº175",
    "Escuela Nº176",
    "Escuela Nº177",
    "Escuela Nº178 - JOSE MARIA GARCIA",
    "Escuela Nº179",
    "Escuela Nº180 - ELINA CUELLO",
    "Escuela Nº181",
    "Escuela Nº182",
    "Escuela Nº183",
    "Escuela Nº184 - DORILA BORCHE DE FERNANDEZ",
    "Escuela Nº185 - MAHATMA GANDHI",
    "Escuela Nº186",
    "Escuela Nº187 - CAP. JUAN ANTONIO ARTIGAS",
    "Escuela Nº188",
    "Escuela Nº189",
    "Escuela Nº190",
    "Escuela Nº191",
    "Escuela Nº192 - SUECIA",
    "Escuela Nº193 - SAINAP AMIR RIOS",
    "Escuela Nº194 - BRIGADIER GRAL. JUAN ANTONIO LAVALLEJA",
    "Escuela Nº195 - CARLOS MARIA DE PENA",
    "Escuela Nº196",
    "Escuela Nº197 - HOLANDA",
    "Escuela Nº198 - ESPAÑA",
    "Escuela Nº199 - MIGUEL GILBERTO TALLAC",
    "Escuela Nº200",
    "Escuela Nº201 - OCTAVIO POSADA",
    "Escuela Nº202 - JUAN JOSE MOROSOLI",
    "Escuela Nº203",
    "Escuela Nº204 - MAESTRO OTTO NIEMANN",
    "Escuela Nº205 - MAESTRO SABAS OLAIZOLA",
    "Escuela Nº206 - ANGEL DODERA",
    "Escuela Nº207",
    "Escuela Nº208 - BALMORIS LLOVERAS",
    "Escuela Nº209",
    "Escuela Nº210",
    "Escuela Nº211 - DORA PUMAR DE GONZALEZ",
    "Escuela Nº212 - COLECTIVIDAD JAPONESA",
    "Escuela Nº213",
    "Escuela Nº214",
    "Escuela Nº215 - ITALIA",
    "Escuela Nº216",
    "Escuela Nº217 - ESTADO DE ISRAEL",
    "Escuela Nº218",
    "Escuela Nº219",
    "Escuela Nº220 - MARIA ROSA SALSAMENDI",
    "Escuela Nº221 - PAUL HARRIS",
    "Escuela Nº222 - ALFREDO MERCADAL",
    "Escuela Nº223",
    "Escuela Nº224",
    "Escuela Nº225",
    "Escuela Nº226 - JUANA DE IBARBOUROU",
    "Escuela Nº227",
    "Escuela Nº228",
    "Escuela Nº229",
    "Escuela Nº230",
    "Escuela Nº231",
    "Escuela Nº232",
    "Escuela Nº233 - RAMON ALVAREZ",
    "Escuela Nº235 - EUDORO MELO",
    "Escuela Nº236 - REPUBLICA DEL PERU",
    "Escuela Nº237",
    "Escuela Nº238",
    "Escuela Nº239",
    "Escuela Nº240",
    "Escuela Nº241",
    "Escuela Nº242 - MARIA NELIDA MARTINEZ DE TORRES",
    "Escuela Nº244",
    "Escuela Nº245",
    "Escuela Nº246",
    "Escuela Nº247",
    "Escuela Nº248 - EUSTAQUIA LLAMOSA",
    "Escuela Nº249",
    "Escuela Nº250 - MEXICO",
    "Escuela Nº251 - DR. JULIO LAGO",
    "Escuela Nº253",
    "Escuela Nº254",
    "Escuela Nº255",
    "Escuela Nº256",
    "Escuela Nº257 - 18 DE MAYO",
    "Escuela Nº258",
    "Escuela Nº259",
    "Escuela Nº260",
    "Escuela Nº261",
    "Escuela Nº262",
    "Escuela Nº263",
    "Escuela Nº264",
    "Escuela Nº266 - ALFONSO BROQUA",
    "Escuela Nº267",
    "Escuela Nº268",
    "Escuela Nº269",
    "Escuela Nº270",
    "Escuela Nº271",
    "Escuela Nº272",
    "Escuela Nº273",
    "Escuela Nº274",
    "Escuela Nº275 - FUNDACION TAPIE PIÑEIRO",
    "Escuela Nº276",
    "Escuela Nº277",
    "Escuela Nº278",
    "Escuela Nº283",
    "Escuela Nº285",
    "Escuela Nº286 - MTRA. OFELIA DE HORTA CAMPODONICO",
    "Escuela Nº287",
    "Escuela Nº288",
    "Escuela Nº289",
    "Escuela Nº290",
    "Escuela Nº291",
    "Escuela Nº292",
    "Escuela Nº293",
    "Escuela Nº294",
    "Escuela Nº295",
    "Escuela Nº296",
    "Escuela Nº297",
    "Escuela Nº298",
    "Escuela Nº299",
    "Escuela Nº300",
    "Escuela Nº301",
    "Escuela Nº302",
    "Escuela Nº303 - ESCUELA DE ARTES Y OFICIOS",
    "Escuela Nº304 - CAMPAMENTO PROF. SALVADOR MAUAD",
    "Escuela Nº305",
    "Escuela Nº307",
    "Escuela Nº308",
    "Escuela Nº309",
    "Escuela Nº310",
    "Escuela Nº311",
    "Escuela Nº312",
    "Escuela Nº313",
    "Escuela Nº314",
    "Escuela Nº315",
    "Escuela Nº316",
    "Escuela NºCAF - CENTRO AGUSTIN FERREIRO",
    "Escuela NºCER - CENTRO DE EDUCACION RURAL",
  ],
  "Cerro Largo": [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3 - JUANA DE IBARBOUROU",
    "Escuela Nº4 - FRUCTUOSO MAZZIOTTA",
    "Escuela Nº5 - MA. JOSEFA GAMIO DE FERREIRO",
    "Escuela Nº6 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº7 - LAURA SILVA DE MACIEL",
    "Escuela Nº8",
    "Escuela Nº9 - GENERAL APARICIO SARAVIA",
    "Escuela Nº10 - SEVERINA SANCHEZ DE PEREZ",
    "Escuela Nº11 - REPUBLICA FEDERATIVA DEL BRASIL",
    "Escuela Nº12",
    "Escuela Nº13 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº18 - LEYENDA PATRIA",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº23",
    "Escuela Nº24",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº28",
    "Escuela Nº29 - JUAN ROSAS",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32 - ENRIQUE BRAYER BLANCO",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35 - DIONISIO DIAZ",
    "Escuela Nº36 - NORBERTO SERGIO",
    "Escuela Nº37 - CARLOS COSSE CORONEL",
    "Escuela Nº38",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº46 - DR. EMILIO ORIBE",
    "Escuela Nº47",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº50",
    "Escuela Nº51",
    "Escuela Nº54",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61 - TULIO RODRIGUEZ F.",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº66 - AGUSTIN FERREIRO",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº74 - GENERAL FRUCTUOSO RIVERA",
    "Escuela Nº75 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº87 - CELIA ECCHER DE BLOCONA",
    "Escuela Nº89 - FERNANDO OTORGUES",
    "Escuela Nº92 - JOSE ENRIQUE RODO",
    "Escuela Nº94",
    "Escuela Nº97",
    "Escuela Nº99 - CLEMENTE ESTABLE",
    "Escuela Nº100",
    "Escuela Nº102",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº107 - REPUBLICA FEDERAL DE ALEMANIA",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112 - ANTONIO GIANOLA",
    "Escuela Nº114 - SAUL URBINA",
    "Escuela Nº117",
    "Escuela Nº119 - REPUBLICA ARGENTINA",
    "Escuela Nº121 - REPUBLICA DE ITALIA",
    "Escuela Nº122",
    "Escuela Nº123",
    "Escuela Nº125",
    "Escuela Nº126",
    "Escuela Nº127",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº130 - SAVINIANO PEREZ",
    "Escuela Nº131 - LUIS CLUZEAU MORTET",
    "Escuela Nº132 - ESPAÑA",
    "Escuela Nº133",
    "Escuela Nº134",
    "Escuela Nº135 - INSP. HENRY RUIZ SARTORIO",
    "Escuela Nº136",
    "Escuela Nº137",
    "Escuela Nº138",
    "Escuela Nº139 - ESTADO DE ISRAEL",
    "Escuela Nº140",
    "Escuela Nº141",
    "Escuela Nº143",
    "Escuela Nº144 - BICENTENARIO",
    "Escuela Nº145",
    "Escuela Nº146",
    "Escuela Nº147",
  ],
  Colonia: [
    "Escuela Nº1 - JOSE ARTIGAS",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3",
    "Escuela Nº4 - JUAN MANUEL BLANES",
    "Escuela Nº5 - MAESTRO HECTOR L. NOAIN",
    "Escuela Nº6 - MANUELA T. NOAIN",
    "Escuela Nº7 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº8 - ADELA ACEVEDO DE VARELA",
    "Escuela Nº9 - BLANCA E. PONS",
    "Escuela Nº10 - ELIAS HUBER",
    "Escuela Nº11 - HERNANDO ARIAS DE SAAVEDRA",
    "Escuela Nº12",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº17",
    "Escuela Nº18",
    "Escuela Nº19 - AMADEO SOLER",
    "Escuela Nº20 - SUIZA",
    "Escuela Nº21",
    "Escuela Nº22 - MAESTRA ISABEL VILLANUSTRE",
    "Escuela Nº23",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27 - AGUSTIN FERREIRO",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32 - JUANA DE IBARBOUROU",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37 - REPUBLICA ARGENTINA",
    "Escuela Nº38 - MTRO. EDUARDO MARIO HENDERSON HERNANDEZ",
    "Escuela Nº39",
    "Escuela Nº40 - JOSE ENRIQUE RODO",
    "Escuela Nº41",
    "Escuela Nº42",
    "Escuela Nº43 - DIONISIO DIAZ",
    "Escuela Nº44",
    "Escuela Nº45",
    "Escuela Nº46 - MAESTRA MAGDALENA FANETTI",
    "Escuela Nº47",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº50",
    "Escuela Nº51",
    "Escuela Nº52",
    "Escuela Nº54",
    "Escuela Nº55 - DR. ALBERTO BOERGER",
    "Escuela Nº56 - JESUALDO SOSA",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64 - DR. LUIS MORQUIO",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75 - RAMON ERRECARTE",
    "Escuela Nº76 - DR. RAMIRO JULIO BORRAS",
    "Escuela Nº78",
    "Escuela Nº79 - AARON DE ANCHORENA",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº83 - TULA SUAREZ DE CUTINELLA ",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº90 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº91",
    "Escuela Nº92 - PLACIDO LAGUNA",
    "Escuela Nº93 - FLORENCIO SANCHEZ",
    "Escuela Nº94",
    "Escuela Nº95",
    "Escuela Nº96",
    "Escuela Nº97",
    "Escuela Nº98 - CLEMENTE ESTABLE",
    "Escuela Nº99",
    "Escuela Nº100",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº106",
    "Escuela Nº109",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº113",
    "Escuela Nº114 - GRAL. JOSE DE SAN MARTIN",
    "Escuela Nº115",
    "Escuela Nº116",
    "Escuela Nº117",
    "Escuela Nº118",
    "Escuela Nº119",
    "Escuela Nº120",
    "Escuela Nº121",
    "Escuela Nº122",
    "Escuela Nº123",
    "Escuela Nº124",
    "Escuela Nº125",
    "Escuela Nº126 - GUILLERMO TELL",
    "Escuela Nº127",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº130",
    "Escuela Nº131",
    "Escuela Nº132 - MAESTRA ALICIA PARDINI",
    "Escuela Nº133",
    "Escuela Nº134",
    "Escuela Nº135",
    "Escuela Nº136",
    "Escuela Nº137 - SOLAR DE ARTIGAS",
    "Escuela Nº138",
    "Escuela Nº139",
    "Escuela Nº140",
    "Escuela Nº142",
    "Escuela Nº144 - CARLOS ALBERTO IRIGARAY",
    "Escuela Nº145",
  ],
  Durazno: [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE ENRIQUE RODO",
    "Escuela Nº3 - FERNAN SILVA VALDES",
    "Escuela Nº4",
    "Escuela Nº5 - DR. ELIAS REGULES",
    "Escuela Nº6 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº7 - JOSE PEDRO VARELA",
    "Escuela Nº8 - DR. MIGUEL C. RUBINO",
    "Escuela Nº9 - JUANA DE IBARBOUROU",
    "Escuela Nº10 - DR. EMILIO PENZA",
    "Escuela Nº11 - BERNARDINA FRAGOSO DE RIVERA",
    "Escuela Nº12 - MAESTRA GLADYS SANTIAGO",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16 - DR. RAMIRO PUCURULL ISLAS",
    "Escuela Nº17",
    "Escuela Nº18 - DIONISIO DIAZ",
    "Escuela Nº19 - MAESTRO ROSENDO BUCHELI",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22 - MAESTRA CELIA GALARZA DE SANCHEZ",
    "Escuela Nº23",
    "Escuela Nº24 - JOAQUIN SUAREZ",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30 - AGUSTIN FARAUT MARTINI",
    "Escuela Nº32 - MAESTRO AGUSTIN FERREIRO",
    "Escuela Nº33 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº34 - JUAN JORGE LLADO WILKINS",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº39 - BRIG. GRAL. FRUCTUOSO RIVERA",
    "Escuela Nº40",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº45",
    "Escuela Nº46",
    "Escuela Nº47 - LUIS RAMON IGARZABAL",
    "Escuela Nº49",
    "Escuela Nº50",
    "Escuela Nº51 - ROSA CABRERA ESPINOLA",
    "Escuela Nº53",
    "Escuela Nº54 - NORBERTO ARAMBURU VEGA",
    "Escuela Nº55",
    "Escuela Nº56 - ANSINA",
    "Escuela Nº57",
    "Escuela Nº60",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65 - JOSE BATLLE Y ORDOÑEZ",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74 - PAUL HARRIS",
    "Escuela Nº75 - BRIGADIER GENERAL MANUEL ORIBE",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº81 - CLEMENTE ESTABLE",
    "Escuela Nº82",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85 - JULIO MARTINEZ OYANGUREN",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº90",
    "Escuela Nº91",
    "Escuela Nº95",
    "Escuela Nº96 - LAS HIGUERAS",
  ],
  Flores: [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE ENRIQUE RODO",
    "Escuela Nº3 - JOSE PEDRO VARELA",
    "Escuela Nº4 - JAIME RIBOT Y MESTRE",
    "Escuela Nº5 - AGUSTIN FERREIRO",
    "Escuela Nº6",
    "Escuela Nº7 - AURORA LIMA IPAR",
    "Escuela Nº9",
    "Escuela Nº10",
    "Escuela Nº13 - CLEMENTE ESTABLE",
    "Escuela Nº15 - SANTOS ARMANDO CASTAINGDEBAT",
    "Escuela Nº16 - DIONISIO DIAZ",
    "Escuela Nº18 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº19 - JUANA DE IBARBOUROU",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23 - FELIPE SANGUINETTI",
    "Escuela Nº25 - JUAN JOSE CASTRO",
    "Escuela Nº27 - ANTONIO J. CAORSI",
    "Escuela Nº28 - MARIA EMILIA BEROT DE BELOQUI",
    "Escuela Nº29 - DON JUAN LATEULADE SAFORES",
    "Escuela Nº31 - REPUBLICA ARGENTINA",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº40 - HAYDEE BLANCA RODRIGUEZ DE OLARTE",
    "Escuela Nº41 - REPUBLICA ITALIANA",
    "Escuela Nº43",
    "Escuela Nº44 - PILAR DE HERRERA DE ARTEAGA",
    "Escuela Nº46",
    "Escuela Nº47 - JOSE MARIA GUERRERO",
    "Escuela Nº48 - FRAY MANUEL UBEDA",
  ],
  Florida: [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3 - DORA VECCHI DE RODRIGUEZ",
    "Escuela Nº4 - DR. JUAN GUGLIELMETTI",
    "Escuela Nº5",
    "Escuela Nº6",
    "Escuela Nº7 - SIMON BOLIVAR",
    "Escuela Nº8 - JOSE CUNEO",
    "Escuela Nº9 - MTRA. LIDIA COLOMBO DE FIOL",
    "Escuela Nº10",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº17 - DR. ALEJANDRO GALLINAL",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21 - WILSON FERREIRA ALDUNATE",
    "Escuela Nº22 - AGUSTIN FERREIRO",
    "Escuela Nº23",
    "Escuela Nº24 - ITALIA",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº28 - DR. LEONEL AGUIRRE",
    "Escuela Nº29 - JUSTINA SANTIN",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33 - JOSE VICTOR MENDIZABAL",
    "Escuela Nº34 - MANUEL FRANCISCO ARTIGAS",
    "Escuela Nº37 - CANADA",
    "Escuela Nº38 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº45 - DR.CYRO GIAMBRUNO",
    "Escuela Nº46",
    "Escuela Nº47 - JUAN BONINO GALLO",
    "Escuela Nº48",
    "Escuela Nº49 - SARA LOPEZ DE FERNANDEZ",
    "Escuela Nº50",
    "Escuela Nº51 - JOSE ENRIQUE RODO",
    "Escuela Nº52 - TOMAS CIRILO CARRERO ANICET",
    "Escuela Nº53",
    "Escuela Nº54",
    "Escuela Nº55 - JULIA VIGNOLY DE GARCIA",
    "Escuela Nº56",
    "Escuela Nº58 - BATALLA DE SARANDI",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº76 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº79",
    "Escuela Nº81",
    "Escuela Nº84 - PEDRO OLIVERI",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº90",
    "Escuela Nº91",
    "Escuela Nº93",
    "Escuela Nº94",
    "Escuela Nº95",
    "Escuela Nº96",
    "Escuela Nº98",
    "Escuela Nº100",
    "Escuela Nº101",
    "Escuela Nº102 - PIEDRA ALTA",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº106",
    "Escuela Nº108 - CLEMENTE ESTABLE",
    "Escuela Nº109 - BRIG.GRAL.FRUCTUOSO RIVERA",
    "Escuela Nº110",
    "Escuela Nº112",
    "Escuela Nº113",
    "Escuela Nº114",
    "Escuela Nº115",
    "Escuela Nº116 - REPUBLICA ARGENTINA",
    "Escuela Nº117 - ISABEL ROSANO STAGI DE BAISON",
    "Escuela Nº118",
    "Escuela Nº123 - JOAQUIN PIERA",
    "Escuela Nº124",
  ],
  Lavalleja: [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3",
    "Escuela Nº4 - GUILLERMINA DIAGO DE PINTOS",
    "Escuela Nº5 - EDUARDO FABINI",
    "Escuela Nº6 - AMELIA MAESO DE PEREIRA",
    "Escuela Nº7 - JUAN JOSE MOROSOLI",
    "Escuela Nº8 - GUILLERMO CUADRI",
    "Escuela Nº9 - MTRO. MANUEL A. LOPEZ TOLEDO",
    "Escuela Nº10 - BRIGADIER GENERAL MANUEL ORIBE",
    "Escuela Nº11 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº12 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº13 - JOAQUIN TORRES GARCIA",
    "Escuela Nº14",
    "Escuela Nº15 - MAESTRO PANTALEON SCOLPINI",
    "Escuela Nº16 - HOMERO GRILLO",
    "Escuela Nº17 - HILDA PISANI DE AMEN",
    "Escuela Nº18 - LEYENDA PATRIA",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34 - AGUSTIN FERREIRO",
    "Escuela Nº35 - FRUCTUOSO RIVERA",
    "Escuela Nº36 - MAESTRO INDALECIO BENGOCHEA RODRIGUEZ",
    "Escuela Nº37",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº41 - PEDRO ARAMENDIA",
    "Escuela Nº44",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº51",
    "Escuela Nº52",
    "Escuela Nº54 - CLEMENTE ESTABLE",
    "Escuela Nº56",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº62",
    "Escuela Nº63 - GENERAL ARTIGAS",
    "Escuela Nº64",
    "Escuela Nº65 - CARLOS MARIA JASO ANCHORENA",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº73",
    "Escuela Nº75",
    "Escuela Nº76 - DIONISIO DIAZ",
    "Escuela Nº77",
    "Escuela Nº79",
    "Escuela Nº83",
    "Escuela Nº85",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº90 - ALBERO GALLINAL HEBER",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº94 - ANTOINE DE SAINT EXUPERY",
    "Escuela Nº95",
    "Escuela Nº96 - FLORENCE NIGHTINGALE",
    "Escuela Nº97",
    "Escuela Nº98",
    "Escuela Nº102 - SANTIAGO RAMON Y CAJAL",
    "Escuela Nº103",
    "Escuela Nº104 - ANA MONTERROSO DE LAVALLEJA",
    "Escuela Nº105 - MIGUEL NAVARRA",
    "Escuela Nº106",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº111 - ESC. HECTOR LEIS RICCETTO",
    "Escuela Nº112 - FEDERICO FROEBEL",
    "Escuela Nº113 - GONZALO GONZALEZ SIMOIS",
    "Escuela Nº114",
    "Escuela Nº115",
    "Escuela Nº117 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº118 - SANTIAGO CHALAR",
    "Escuela Nº119 - PERICO",
    "Escuela Nº120",
    "Escuela Nº122 - CAMPAMENTO LAS SIERRAS",
  ],
  Maldonado: [
    "Escuela Nº1 - JOSE PEDRO RAMIREZ",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3 - JUAN DE DIOS CURBELO",
    "Escuela Nº4",
    "Escuela Nº5 - ALEJANDRO Y SAMUEL LAFONE",
    "Escuela Nº6 - JULIAN R. GOICOECHEA",
    "Escuela Nº7 - GRAL. JOSE DE SAN MARTIN",
    "Escuela Nº8 - JOSE ENRIQUE RODO",
    "Escuela Nº9 - JOSE ARTIGAS",
    "Escuela Nº10 - CAYETANO SILVA",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº13 - VIRREY PEDRO DE CEVALLOS",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº17 - ING. AGRONOMO JORGE AZNAREZ",
    "Escuela Nº18",
    "Escuela Nº19 - MTRA. RAQUEL RODRIGUEZ CANALE DE BARRIOS",
    "Escuela Nº20",
    "Escuela Nº21 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº24 - MAESTRO CANDIDO VILLAR",
    "Escuela Nº25 - GRAL. LEONARDO OLIVERA",
    "Escuela Nº26 - AGUSTIN FERREIRO",
    "Escuela Nº27 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37 - FRANCISCO PIRIA",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº41",
    "Escuela Nº42 - MARGARITA MUNIZ",
    "Escuela Nº43",
    "Escuela Nº44 - PETRONA FONTES DE BONILLA",
    "Escuela Nº45 - DR. ALFONSO LAMAS",
    "Escuela Nº46",
    "Escuela Nº47",
    "Escuela Nº48",
    "Escuela Nº50 - MAESTRO ANTONIO CAMACHO",
    "Escuela Nº52 - ELENA MARROCHE DE MUSSIO",
    "Escuela Nº53",
    "Escuela Nº54",
    "Escuela Nº55",
    "Escuela Nº56 - CLEMENTE ESTABLE",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64 - BRIGADIER GENERAL FRUCTUOSO RIVERA",
    "Escuela Nº66 - DIONISIO DIAZ",
    "Escuela Nº67",
    "Escuela Nº69",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº78",
    "Escuela Nº79 - ROSALIA DE CASTRO - ANEXO TRANSICION A LA VIDA ADULTA",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82 - JUANA DE IBARBOUROU",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85 - COLONIA ESCOLAR EMILIO ORIBE",
    "Escuela Nº86 - CENTRO ESCOLAR DE CAPACITACION RURAL - C.E.C.RU.",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº90 - JUAN H. GRIECO -  CELIA CATTALURDA",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº93",
    "Escuela Nº94",
    "Escuela Nº95 - ESPAÑA",
    "Escuela Nº96 - JUAN JOSE MUÑOZ",
    "Escuela Nº97 - TACUABE",
    "Escuela Nº98 - ISLAS CANARIAS",
    "Escuela Nº99",
    "Escuela Nº100",
    "Escuela Nº102",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº106",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112",
    "Escuela Nº113",
  ],
  Montevideo: [
    "Escuela Nº2 - REPUBLICA ARGENTINA",
    "Escuela Nº3 - FRANCIA",
    "Escuela Nº4 - ARTIGAS",
    "Escuela Nº5 - JOSE PEDRO VARELA",
    "Escuela Nº6 - ESTADOS UNIDOS DE AMERICA",
    "Escuela Nº7 - ROSA MARINO",
    "Escuela Nº8 - REPUBLICA DE HAITI",
    "Escuela Nº9 - DR. AURELIANO RODRIGUEZ LARRETA",
    "Escuela Nº10 - REPUBLICA DE EL SALVADOR",
    "Escuela Nº11 - ABRAHAM LINCOLN",
    "Escuela Nº13 - JOAQUIN MESTRE",
    "Escuela Nº14 - JOSE DE SAN MARTIN",
    "Escuela Nº16 - SUECIA",
    "Escuela Nº17 - BRASIL",
    "Escuela Nº19 - GRUPO ESCOLAR FELIPE SANGUINETTI",
    "Escuela Nº20 - GRUPO ESCOLAR FELIPE SANGUINETTI",
    "Escuela Nº21 - ALEMANIA",
    "Escuela Nº23 - TOMAS CLARAMUNT",
    "Escuela Nº24 - JOAQUIN SUAREZ",
    "Escuela Nº25 - RUY BARBOSA",
    "Escuela Nº26 - PAISES BAJOS",
    "Escuela Nº27 - DR. EDUARDO ACEVEDO",
    "Escuela Nº28 - REPUBLICA DE PANAMA",
    "Escuela Nº29 - CHECOSLOVAQUIA",
    "Escuela Nº30 - ANTONIO DIONISIO LUSSICH",
    "Escuela Nº31 - GRUPO ESCOLAR JACOBO VARELA",
    "Escuela Nº32 - SIMON BOLIVAR",
    "Escuela Nº33 - REPUBLICA DE COLOMBIA",
    "Escuela Nº34 - CONGRESO DE ABRIL DE 1813",
    "Escuela Nº35 - REPUBLICA DE GUATEMALA",
    "Escuela Nº36 - BELGICA",
    "Escuela Nº37 - BRIGADIER GENERAL MANUEL ORIBE",
    "Escuela Nº38 - HORACIO DURA",
    "Escuela Nº39 - GRECIA",
    "Escuela Nº40 - MEXICO",
    "Escuela Nº41 - REPUBLICA DE ECUADOR",
    "Escuela Nº42 - REPUBLICA DE BOLIVIA",
    "Escuela Nº44 - PRESBITERO JOSE BENITO LAMAS",
    "Escuela Nº45 - LEONOR HOURTICOU",
    "Escuela Nº47 - WASHINGTON BELTRAN - ROMILDO RISSO",
    "Escuela Nº48 - AUSTRIA",
    "Escuela Nº49 - REPUBLICA DE NICARAGUA",
    "Escuela Nº50 - JUAN MANUEL BLANES",
    "Escuela Nº51 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº52 - REPUBLICA DOMINICANA",
    "Escuela Nº53 - GRAN BRETAÑA",
    "Escuela Nº54 - DR. CLAUDIO WILLIMAN",
    "Escuela Nº55",
    "Escuela Nº56 - PEDRO A. MACCIO",
    "Escuela Nº57 - JUAN MANUEL DE VEDIA",
    "Escuela Nº58 - REPUBLICA DE SUDAFRICA",
    "Escuela Nº59",
    "Escuela Nº60 - GRUPO ESCOLAR JOSE H. FIGUEIRA",
    "Escuela Nº61 - KONRAD ADENAUER",
    "Escuela Nº62 - SUIZA",
    "Escuela Nº63 - LUIS CINCINATO BOLLO",
    "Escuela Nº64 - EDUARDO FABINI",
    "Escuela Nº65 - PORTUGAL",
    "Escuela Nº66 - JOSE BELLONI",
    "Escuela Nº68 - ESTADO DE ISRAEL",
    "Escuela Nº70 - JOSE BATLLE Y ORDOÑEZ",
    "Escuela Nº72 - JAPON",
    "Escuela Nº76 - GRUPO ESCOLAR FELIPE SANGUINETTI",
    "Escuela Nº79 - DANIEL FERNANDEZ CRESPO",
    "Escuela Nº80 - BRIG. GRAL. JUAN ANTONIO LAVALLEJA",
    "Escuela Nº81 - MAHATMA GANDHI",
    "Escuela Nº83 - DR. MARTIN R. ETCHEGOYEN",
    "Escuela Nº84 - DR. ABEL JULIAN PEREZ",
    "Escuela Nº88 - EDMUNDO DE AMICIS",
    "Escuela Nº89 - IBIRAY",
    "Escuela Nº90 - PEDRO FIGARI",
    "Escuela Nº92 - ARTURO CARBONELL Y MIGAL",
    "Escuela Nº93 - GRUPO ESCOLAR MARIA NOYA",
    "Escuela Nº94 - JOSE ROGER BALET ",
    "Escuela Nº95 - UNION EUROPEA",
    "Escuela Nº96 - FRANCISCO ACUÑA DE FIGUEROA",
    "Escuela Nº97 - FEDERICO GARCIA LORCA",
    "Escuela Nº98 - DR. JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº99 - REPUBLICA DE HONDURAS",
    "Escuela Nº100 - HECTOR FIGOLI",
    "Escuela Nº101 - CARMELO COLMAN",
    "Escuela Nº102 - JUANA DE IBARBOUROU",
    "Escuela Nº103",
    "Escuela Nº105 - CARLOS VAZ FERREIRA",
    "Escuela Nº107 - REPUBLICA DE COSTA RICA",
    "Escuela Nº109 - FLORENCIO SANCHEZ",
    "Escuela Nº110 - FERNAN SILVA VALDES",
    "Escuela Nº112",
    "Escuela Nº113 - ZELMAR MICHELINI",
    "Escuela Nº115 - FRANCISCO JOSE DEBALI",
    "Escuela Nº116 - LA VALLETTA - REPUBLICA DE MALTA",
    "Escuela Nº117 - NACIONES UNIDAS",
    "Escuela Nº119",
    "Escuela Nº120 - MANUEL BELGRANO",
    "Escuela Nº121 - DR. EVARISTO CIGANDA",
    "Escuela Nº122 - ISLAS CANARIAS",
    "Escuela Nº123",
    "Escuela Nº124",
    "Escuela Nº125 - REPUBLICA LIBANESA",
    "Escuela Nº126",
    "Escuela Nº127 - JUAN RAMON JIMENEZ",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº130 - ANDRES BELLO",
    "Escuela Nº131 - REPUBLICA DE CHILE",
    "Escuela Nº136 - JUAN MONTALVO",
    "Escuela Nº137 - GRUPO ESCOLAR MARIA NOYA",
    "Escuela Nº138 - SERAFIN J. GARCIA",
    "Escuela Nº139 - CALABRIA",
    "Escuela Nº140 - ESPERANZA VISCAY DE FULHER",
    "Escuela Nº141 - REINO DE MALASIA",
    "Escuela Nº142 - REPUBLICA DEL PERU",
    "Escuela Nº143 - BARTOLOME HIDALGO",
    "Escuela Nº144 - JOSE GARIBALDI",
    "Escuela Nº145 - MAESTRA CATALINA VELAZQUEZ",
    "Escuela Nº146 - LUIS BATLLE BERRES",
    "Escuela Nº148 - REPUBLICA DE COREA",
    "Escuela Nº149 - ISABEL ABELENDA DE PAZOS",
    "Escuela Nº150 - CATALUNYA",
    "Escuela Nº151 - ALBERT EINSTEIN",
    "Escuela Nº152 - MANUEL FRANCISCO ARTIGAS",
    "Escuela Nº153 - BENJAMIN FRANKLIN",
    "Escuela Nº154",
    "Escuela Nº155",
    "Escuela Nº156 - ARMENIA",
    "Escuela Nº157 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº158",
    "Escuela Nº159",
    "Escuela Nº160 - DR. LUIS MORQUIO",
    "Escuela Nº161 - ALEJANDRIA",
    "Escuela Nº163 - GALICIA",
    "Escuela Nº166 - MARIA VITTORI",
    "Escuela Nº167 - JUAN JOSE MOROSOLI",
    "Escuela Nº168 - RUMANIA",
    "Escuela Nº169 - LUISA CASTERAN",
    "Escuela Nº170",
    "Escuela Nº172 - JOSE MARTI",
    "Escuela Nº173",
    "Escuela Nº174 - CANADA",
    "Escuela Nº175 - COMANDANTE PEDRO CAMPBELL",
    "Escuela Nº176 - JUAN BALBIN GONZALEZ VALLEJO",
    "Escuela Nº177 - CRISTOBAL COLON",
    "Escuela Nº178 - MARTIN LUTHER KING",
    "Escuela Nº179 - TOMAS BERRETA",
    "Escuela Nº180 - SUSANA SOCA",
    "Escuela Nº181 - MAESTRA ELENA QUINTEROS",
    "Escuela Nº182 - EGIPTO",
    "Escuela Nº183 - NELSON MANDELA",
    "Escuela Nº184",
    "Escuela Nº185 - UNESCO",
    "Escuela Nº187 - DR. LUIS ALBERTO DE HERRERA",
    "Escuela Nº188",
    "Escuela Nº189 - BENITO JUAREZ",
    "Escuela Nº190 - AUSTRALIA",
    "Escuela Nº191 - PAISES DEL MERCOSUR",
    "Escuela Nº192 - JOAQUIN TORRES GARCIA",
    "Escuela Nº193 - JOSE LUIS ZORRILLA DE SAN MARTIN",
    "Escuela Nº195 - HUMBERTO ZARRILLI",
    "Escuela Nº196 - DEBORAH VITALE D'AMICO",
    "Escuela Nº197 - ANA BRUZZONE DE SCARONE",
    "Escuela Nº198",
    "Escuela Nº199",
    "Escuela Nº200 - DOCTOR RICARDO CARITAT",
    "Escuela Nº201 - ESPAÑA",
    "Escuela Nº203 - ELOISA GARCIA ETCHEGOYEN DE LORENZO",
    "Escuela Nº204 - EMILIO VERDESIO",
    "Escuela Nº205 - OBRA MORQUIO",
    "Escuela Nº206 - ALBERTO A. ALVES ",
    "Escuela Nº207 - E.T.R.O.",
    "Escuela Nº208",
    "Escuela Nº209 - GERARDO CUESTA",
    "Escuela Nº210 - PETRONA VIERA",
    "Escuela Nº211",
    "Escuela Nº212 - PAULO FREIRE",
    "Escuela Nº213 - ENRIQUETA COMPTE Y RIQUE",
    "Escuela Nº214",
    "Escuela Nº215 - GABRIELA MISTRAL",
    "Escuela Nº216",
    "Escuela Nº217 - ROLAND MOOR",
    "Escuela Nº218 - MARIA CLARA ZABALA",
    "Escuela Nº219 - OLYMPIA FERNANDEZ",
    "Escuela Nº220",
    "Escuela Nº221 - JUANA DE AMERICA",
    "Escuela Nº222",
    "Escuela Nº223 - REINA REYES",
    "Escuela Nº224 - JOSE ROGER BALET ",
    "Escuela Nº225 - DOMINGO ARENA",
    "Escuela Nº226 - MELCHORA CUENCA",
    "Escuela Nº227",
    "Escuela Nº228",
    "Escuela Nº229",
    "Escuela Nº230 - BENITA BERRO DE VARELA",
    "Escuela Nº231",
    "Escuela Nº232",
    "Escuela Nº233 - SALOME UREÑA DE HENRIQUEZ",
    "Escuela Nº234",
    "Escuela Nº235",
    "Escuela Nº236 - TALLER PROTEGIDO AGRARIO",
    "Escuela Nº237",
    "Escuela Nº238 - CADETE HEBER W.CASTIGLIONI CASTRO",
    "Escuela Nº239",
    "Escuela Nº240 - PAUL HARRIS",
    "Escuela Nº241",
    "Escuela Nº242",
    "Escuela Nº243 - JULIO J. CASAL",
    "Escuela Nº244 - EDUARDO MATEO",
    "Escuela Nº245",
    "Escuela Nº246 - GLADYS NOTAROBERTO DE FLORES",
    "Escuela Nº247",
    "Escuela Nº248",
    "Escuela Nº249",
    "Escuela Nº250 - DR. EMILIO FRUGONI",
    "Escuela Nº251 - GENERAL FRUCTUOSO RIVERA",
    "Escuela Nº252",
    "Escuela Nº253",
    "Escuela Nº254 - DOCTOR AQUILES LANZA",
    "Escuela Nº255",
    "Escuela Nº256",
    "Escuela Nº257",
    "Escuela Nº258 - JUAN BAUTISTA CROSA DE PINEROLO",
    "Escuela Nº259 - C.E.P.O.A.",
    "Escuela Nº261 - COLONIA ESCOLAR MALVIN",
    "Escuela Nº262 - CLEMENTE ESTABLE",
    "Escuela Nº263 - CNEL. LORENZO LATORRE",
    "Escuela Nº264",
    "Escuela Nº265 - VIRGILIO SCARABELLI ALBERTI",
    "Escuela Nº266",
    "Escuela Nº267 - EUSKADI",
    "Escuela Nº268 - DR. ARTURO LUSSICH",
    "Escuela Nº269 - MAESTRO JULIO CASTRO",
    "Escuela Nº270",
    "Escuela Nº271 - ANA FRANK",
    "Escuela Nº273 - ESC. DARDO ORTIZ",
    "Escuela Nº274 - DR. HECTOR LORENZO RIOS",
    "Escuela Nº275 - REPUBLICA DE CROACIA",
    "Escuela Nº276 - MAESTRO CARLOS CHASSALE",
    "Escuela Nº277 - ATILIO BIANCHI - ELENA DANERO",
    "Escuela Nº278",
    "Escuela Nº279",
    "Escuela Nº280 - COMPLEJO EDUCATIVO ELOISA GARCIA ETCHEGOYEN DE LORENZO",
    "Escuela Nº282",
    "Escuela Nº286",
    "Escuela Nº287",
    "Escuela Nº288",
    "Escuela Nº289",
    "Escuela Nº290 - ALFREDO ZITARROSA",
    "Escuela Nº291",
    "Escuela Nº292 - PROF. ALFREDO TRAVERSONI",
    "Escuela Nº294",
    "Escuela Nº295",
    "Escuela Nº297",
    "Escuela Nº298",
    "Escuela Nº299",
    "Escuela Nº300 - FLORENCIA ROSAS",
    "Escuela Nº301",
    "Escuela Nº302 - KHALIL GIBRAN",
    "Escuela Nº303 - OEA",
    "Escuela Nº305 - MARIA MONTESSORI",
    "Escuela Nº306 - MICAELA GUYUNUSA",
    "Escuela Nº307 - MARGARITA XIRGU",
    "Escuela Nº308",
    "Escuela Nº309",
    "Escuela Nº310 - HUGO BALZO",
    "Escuela Nº311",
    "Escuela Nº312",
    "Escuela Nº313",
    "Escuela Nº314",
    "Escuela Nº315",
    "Escuela Nº316",
    "Escuela Nº317 - ISLAS BALEARES",
    "Escuela Nº318 - REPUBLICA DE LITUANIA",
    "Escuela Nº319 - REPUBLICA POPULAR CHINA",
    "Escuela Nº320",
    "Escuela Nº322",
    "Escuela Nº323 - ITALIA ",
    "Escuela Nº324",
    "Escuela Nº325 - JOSEFA CAMEJO",
    "Escuela Nº326",
    "Escuela Nº327",
    "Escuela Nº328 - ALFREDO TRAVERSONI",
    "Escuela Nº329 - MAESTRO JESUALDO SOSA",
    "Escuela Nº330",
    "Escuela Nº331 - MTRO. DANIEL ALBERTO FERNANDEZ COBELLI",
    "Escuela Nº332",
    "Escuela Nº334",
    "Escuela Nº335",
    "Escuela Nº336",
    "Escuela Nº337",
    "Escuela Nº338",
    "Escuela Nº339 - ROMA",
    "Escuela Nº340",
    "Escuela Nº341",
    "Escuela Nº342",
    "Escuela Nº343 - APARICIO SARAVIA",
    "Escuela Nº344 - REPUBLICA BOLIVARIANA DE VENEZUELA",
    "Escuela Nº345",
    "Escuela Nº346",
    "Escuela Nº347",
    "Escuela Nº348",
    "Escuela Nº350",
    "Escuela Nº351",
    "Escuela Nº352 - COMPLEJO EDUCATIVO PASO DE LA ARENA",
    "Escuela Nº353 - HENRY DUNANT",
    "Escuela Nº354",
    "Escuela Nº355 - COMPLEJO EDUCATIVO 'QUINTA DE LUIS BATLLE'",
    "Escuela Nº356",
    "Escuela Nº357",
    "Escuela Nº359 - ANA VINOCUR",
    "Escuela Nº360 - DR. JANUSZ KORCZAK",
    "Escuela Nº361 - FEDERACION RUSA",
    "Escuela Nº362",
    "Escuela Nº363",
    "Escuela Nº364 - REPUBLICA DE BULGARIA",
    "Escuela Nº365 - ATAHUALPA DEL CIOPPO",
    "Escuela Nº366",
    "Escuela Nº367 - REPUBLICA DE SURINAM",
    "Escuela Nº368",
    "Escuela Nº369",
    "Escuela Nº370",
    "Escuela Nº371",
    "Escuela Nº372",
    "Escuela Nº373",
    "Escuela Nº374",
    "Escuela Nº375",
    "Escuela Nº376",
    "Escuela Nº377",
    "Escuela Nº378",
    "Escuela Nº379",
    "Escuela Nº380",
    "Escuela Nº381 - LOS MUCHACHOS",
    "Escuela Nº382 - MAESTRO RUBEN LENA",
    "Escuela Nº383",
    "Escuela Nº384",
    "Escuela Nº385",
    "Escuela Nº386",
    "Escuela Nº387 - CENTRO TRANSICION A LA VIDA ADULTA",
    "Escuela Nº388",
    "Escuela Nº389",
    "Escuela Nº390",
    "Escuela Nº391",
    "Escuela Nº392",
    "Escuela Nº393",
    "Escuela Nº394",
    "Escuela Nº395",
    "Escuela Nº396",
    "Escuela Nº397",
    "Escuela Nº398",
    "Escuela Nº399",
    "Escuela Nº400",
    "Escuela Nº401",
    "Escuela Nº402",
    "Escuela Nº403",
    "Escuela Nº404",
    "Escuela Nº405",
    "Escuela Nº407",
    "Escuela PEREZ - CENTRO CULTURAL Y ARTISTICO CASA DE PEREZ",
  ],
  Paysandú: [
    "Escuela Nº1 - JOSE PEDRO VARELA",
    "Escuela Nº2 - JOSE G. ARTIGAS",
    "Escuela Nº4 - ANGELA A. PEREZ",
    "Escuela Nº5 - JOSE H. FIGUEIRA",
    "Escuela Nº6 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº7",
    "Escuela Nº8 - JOHN F. KENNEDY",
    "Escuela Nº9",
    "Escuela Nº10",
    "Escuela Nº11 - MIGUEL DE CERVANTES SAAVEDRA",
    "Escuela Nº12",
    "Escuela Nº13 - REPUBLICA ARGENTINA",
    "Escuela Nº14 - BRIG. GRAL. JUAN A. LAVALLEJA",
    "Escuela Nº15",
    "Escuela Nº16 - DIONISIO DIAZ",
    "Escuela Nº17 - JOSE MARIA FIRPO",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº24",
    "Escuela Nº25",
    "Escuela Nº26 - DR. JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº27 - AGUSTIN FERREIRO",
    "Escuela Nº28",
    "Escuela Nº29 - VAIMACA PIRU",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33 - MAESTRO HECTOR FERRARI",
    "Escuela Nº34 - JUAN MANUEL BLANES",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº41",
    "Escuela Nº42 - JUANA DE IBARBOUROU",
    "Escuela Nº43 - PEDRO JACOBO ETCHEMENDI",
    "Escuela Nº44",
    "Escuela Nº45",
    "Escuela Nº46",
    "Escuela Nº47",
    "Escuela Nº48 - QUEGUAY",
    "Escuela Nº49 - MTRA. MARIA TERESA MACRI",
    "Escuela Nº50 - DIONISIO DIAZ ",
    "Escuela Nº51",
    "Escuela Nº52",
    "Escuela Nº53",
    "Escuela Nº54",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60 - DON LUIS IGNACIO",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº84",
    "Escuela Nº85 - JUAN CARLOS HENDERSON",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº89 - LAUREANO TACUABE",
    "Escuela Nº90 - SAUCE DEL QUEGUAY ARRIBA",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº93",
    "Escuela Nº94 - GENERAL LEANDRO GOMEZ ",
    "Escuela Nº95 - LUISA LUISI",
    "Escuela Nº96 - CLEMENTE ESTABLE",
    "Escuela Nº97",
    "Escuela Nº98",
    "Escuela Nº99",
    "Escuela Nº100",
    "Escuela Nº101",
    "Escuela Nº102",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº106 - APOLO RONCHI",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112 - ESTADO DE ISRAEL",
    "Escuela Nº113",
    "Escuela Nº114",
    "Escuela Nº115",
    "Escuela Nº116",
  ],
  "Río Negro": [
    "Escuela Nº1 - JOSE ARTIGAS",
    "Escuela Nº2 - REPUBLICA DE FINLANDIA",
    "Escuela Nº3",
    "Escuela Nº4",
    "Escuela Nº5 - JOSE ENRIQUE RODO",
    "Escuela Nº6 - AGUSTIN FERREIRO",
    "Escuela Nº7 - REPUBLICA ARGENTINA",
    "Escuela Nº8 - ALEMANIA",
    "Escuela Nº9",
    "Escuela Nº10 - SIMON BOLIVAR",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº14",
    "Escuela Nº16",
    "Escuela Nº17 - DOMINGO DE ARCE",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº32 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº33",
    "Escuela Nº34 - CLEMENTE ESTABLE",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº41",
    "Escuela Nº42",
    "Escuela Nº43 - FERNAN SILVA VALDES",
    "Escuela Nº44",
    "Escuela Nº45",
    "Escuela Nº46",
    "Escuela Nº47",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº50",
    "Escuela Nº52 - DR. SEMIRAMIDES ZEBALLOS",
    "Escuela Nº53 - ITALIA",
    "Escuela Nº54",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº59 - MAHATMA GANDHI",
    "Escuela Nº60 - DR. MARIO CARMINATTI",
    "Escuela Nº61",
    "Escuela Nº62 - FRUCTUOSO RIVERA",
    "Escuela Nº64",
    "Escuela Nº65",
    "Escuela Nº66 - JUANA DE IBARBOUROU",
    "Escuela Nº67",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº73",
    "Escuela Nº75",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº81",
  ],
  Rivera: [
    "Escuela Nº1 - ARTIGAS",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3 - ELENA NAVARRO BENTANCUR",
    "Escuela Nº4",
    "Escuela Nº5 - GABRIELA MISTRAL",
    "Escuela Nº6 - EDUARDO FABINI",
    "Escuela Nº7 - BRASIL",
    "Escuela Nº8 - REPUBLICA ARGENTINA",
    "Escuela Nº9 - CRISTOBAL COLON",
    "Escuela Nº10",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº17",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº24",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38 - AGUSTIN FERREIRO",
    "Escuela Nº40",
    "Escuela Nº41",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44 - REPUBLICA DE ITALIA",
    "Escuela Nº45 - ANDRESITO",
    "Escuela Nº46",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº50 - DIONISIO DIAZ",
    "Escuela Nº52",
    "Escuela Nº53",
    "Escuela Nº54 - MIGUEL AGUERRE ARISTEGUI",
    "Escuela Nº58",
    "Escuela Nº59 - INSTRUCCIONES DEL AÑO XIII",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75 - REPUBLICA BOLIVARIANA DE VENEZUELA",
    "Escuela Nº76",
    "Escuela Nº79 - PEDRO BERNARDINO RODRIGUEZ PAIVA",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº84",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº90",
    "Escuela Nº91",
    "Escuela Nº92 - ENRIQUE BELTRAME",
    "Escuela Nº93 - SIMON BOLIVAR",
    "Escuela Nº94 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº95",
    "Escuela Nº96 - JAPON",
    "Escuela Nº98",
    "Escuela Nº99",
    "Escuela Nº101 - CENTRO EXPERIMENTAL DE LECTURA Y ESCRITURA DE RIVERA - C.E.L.E.R.",
    "Escuela Nº102",
    "Escuela Nº104",
    "Escuela Nº105 - MTRO. VICENTE FOCH PUNTIGLIANO",
    "Escuela Nº106 - SANTIAGO NAVARRO CEBALLOS",
    "Escuela Nº107",
    "Escuela Nº108 - MARGARITA ISNARDI",
    "Escuela Nº109 - CLEMENTE ESTABLE",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112 - AGUSTIN RAMON BISIO",
    "Escuela Nº113 - ESPAÑA",
    "Escuela Nº114",
    "Escuela Nº115 - JUANA DE IBARBOUROU",
    "Escuela Nº116",
    "Escuela Nº117",
    "Escuela Nº119",
    "Escuela Nº120",
    "Escuela Nº121",
    "Escuela Nº122",
    "Escuela Nº123",
    "Escuela Nº124 - REPUBLICA POPULAR DE CHINA",
    "Escuela Nº125",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº131",
    "Escuela Nº132 - POETA LALO MENDOZA",
    "Escuela Nº133",
    "Escuela Nº134 - REPUBLICA FRANCESA",
    "Escuela Nº135",
    "Escuela Nº137",
    "Escuela Nº138",
    "Escuela Nº139",
    "Escuela Nº140 - FRANCISCO SIÑERIZ",
    "Escuela Nº141",
    "Escuela Nº143",
    "Escuela Nº144 - PAUL HARRIS",
    "Escuela Nº145",
    "Escuela Nº149",
    "Escuela Nº150 - REPUBLICA LIBANESA",
    "Escuela Nº152",
    "Escuela Nº153",
    "Escuela Nº154",
    "Escuela Nº155",
    "Escuela Nº156",
  ],
  Rocha: [
    "Escuela Nº1 - JOSE PEDRO RAMIREZ",
    "Escuela Nº2 - JOSE PEDRO VARELA",
    "Escuela Nº3 - CLEMENTE ESTABLE",
    "Escuela Nº4 - JUAN A. LAVALLEJA",
    "Escuela Nº5 - DR. JAVIER BARRIOS AMORIN",
    "Escuela Nº7 - ARTIGAS",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº14",
    "Escuela Nº16",
    "Escuela Nº17 - AGUSTIN FERREIRO",
    "Escuela Nº18",
    "Escuela Nº19 - DIONISIO DIAZ",
    "Escuela Nº20",
    "Escuela Nº21 - JOSE HENRIQUEZ FIGUEIRA",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº26",
    "Escuela Nº27 - BENJAMIN SIERRA Y SIERRA",
    "Escuela Nº28 - REPUBLICA FEDERATIVA DE BRASIL",
    "Escuela Nº29 - JOSE IGNACIO URIARTE",
    "Escuela Nº31 - CLEMENTE LUCRECIO FUNES MOLINA",
    "Escuela Nº32 - JOSE H. FIGUEIRA",
    "Escuela Nº33 - SIXTO SILVINO CORREA MEDEIROS",
    "Escuela Nº35",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº40",
    "Escuela Nº41 - DR. HECTOR LUCIAN CANZANI",
    "Escuela Nº42",
    "Escuela Nº43 - GRAL. FRUCTUOSO RIVERA",
    "Escuela Nº44 - FELICIA BANAT",
    "Escuela Nº45 - JUANA DE IBARBOUROU",
    "Escuela Nº47 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº49 - SARGENTO FRANCISCO DE LOS SANTOS",
    "Escuela Nº50",
    "Escuela Nº51",
    "Escuela Nº52",
    "Escuela Nº53",
    "Escuela Nº54 - CENTRO ECOLOGICO INTEGRADO AL MEDIO RURAL - C.E.I.ME.R",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº61",
    "Escuela Nº63",
    "Escuela Nº65 - AGOSTO ALVAREZ",
    "Escuela Nº70 - GRAL. LEONARDO OLIVERA",
    "Escuela Nº72 - PEREGRINA BALBOA",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº80 - DR. VICTOR HUGO BRIOZZO",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº83",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº88 - CONSTANCIO C. VIGIL",
    "Escuela Nº90 - REPUBLICA ITALIANA",
    "Escuela Nº91",
    "Escuela Nº93",
    "Escuela Nº94 - COL. CNEL. LEONARDO OLIVERA",
    "Escuela Nº95",
    "Escuela Nº96",
    "Escuela Nº97 - MARIA NILA LARROSA MARTINEZ",
    "Escuela Nº98",
    "Escuela Nº99 - IRMA AYCAGUER DE BONINO",
    "Escuela Nº100",
    "Escuela Nº101",
    "Escuela Nº102 - LAURO AYESTARAN",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105 - ISAAC FERREIRA CORREA",
    "Escuela Nº106",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº110",
  ],
  Salto: [
    "Escuela Nº1 - GRAL. JOSE GERVASIO ARTIGAS",
    "Escuela Nº2 - ETELVINA MIGLIARO",
    "Escuela Nº3 - JOSE PEDRO VARELA",
    "Escuela Nº4 - DR. JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº5 - JOAQUIN SANT'ANNA",
    "Escuela Nº6",
    "Escuela Nº7",
    "Escuela Nº8 - JOSE BATLLE Y ORDOÑEZ",
    "Escuela Nº9",
    "Escuela Nº10 - ITALIA",
    "Escuela Nº11 - VICTOR R. LIMA",
    "Escuela Nº12 - FELICIANO VIERA",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15 - PEDRO AMBROSONI",
    "Escuela Nº16 - REPUBLICA DE ARMENIA",
    "Escuela Nº17",
    "Escuela Nº18",
    "Escuela Nº19 - GRAL. JUAN A. LAVALLEJA",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23 - ARQUITECTO PEDRO OSCAR AMBROSONI APA",
    "Escuela Nº24",
    "Escuela Nº25 - MELCHORA CUENCA",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº38 - IRINEO LEGUIZAMO",
    "Escuela Nº40 - CARMELO DE ARZADUN",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº45 - DIONISIO DIAZ",
    "Escuela Nº47 - JUANA DE IBARBOUROU",
    "Escuela Nº48 - MARIA INES FORTEZA",
    "Escuela Nº49",
    "Escuela Nº50 - BERNARDINO CORREA PAIVA",
    "Escuela Nº52 - AGUSTIN FERREIRO",
    "Escuela Nº54",
    "Escuela Nº56 - LUIS MARIO ROASCIO",
    "Escuela Nº57 - CNEL. SANTIAGO ARTIGAS",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64 - ESPAÑA",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº69 - PASCUAL HARRIAGUE",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº78 - HORACIO QUIROGA",
    "Escuela Nº80 - EVARISTO ALVAREZ",
    "Escuela Nº81 - ENRIQUE AMORIN",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº91 - PORTUGAL",
    "Escuela Nº92 - MARTIN ELIZAINCIN",
    "Escuela Nº93",
    "Escuela Nº95 - CLEMENTE ESTABLE",
    "Escuela Nº97 - MAESTRA MARIA DOMINGA RAVAGNI",
    "Escuela Nº98 - DR. JOSE ENRIQUE RODO",
    "Escuela Nº99 - BRIGADIER GENERAL MANUEL ORIBE",
    "Escuela Nº100",
    "Escuela Nº101",
    "Escuela Nº103 - GRACIELA CASTELLINI DE FERRERE",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº106",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº111",
    "Escuela Nº112 - REPUBLICA ARGENTINA",
    "Escuela Nº113",
    "Escuela Nº114",
    "Escuela Nº115",
    "Escuela Nº116",
    "Escuela Nº117",
    "Escuela Nº118 - COLONIA ESCOLAR TERMAS DEL ARAPEY",
    "Escuela Nº119",
    "Escuela Nº120",
    "Escuela Nº121",
    "Escuela Nº124",
    "Escuela Nº126",
    "Escuela Nº127",
    "Escuela Nº129 - JAURES LAMARQUE PONS",
    "Escuela Nº130",
    "Escuela Nº131",
    "Escuela Nº132",
    "Escuela Nº133",
    "Escuela Nº134",
    "Escuela Nº135",
    "Escuela Nº136",
    "Escuela Nº137",
  ],
  "San José": [
    "Escuela Nº1 - MARIA ESPINOLA ESPINOLA",
    "Escuela Nº2",
    "Escuela Nº5",
    "Escuela Nº6",
    "Escuela Nº7",
    "Escuela Nº8",
    "Escuela Nº9",
    "Escuela Nº10",
    "Escuela Nº11",
    "Escuela Nº12",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16",
    "Escuela Nº17 - ABNER PRADA",
    "Escuela Nº18",
    "Escuela Nº19",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23 - WENCESLAO VARELA",
    "Escuela Nº24",
    "Escuela Nº25",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30 - AGUSTIN FERREIRO",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº41",
    "Escuela Nº42",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº45 - JOSE PEDRO VARELA",
    "Escuela Nº46 - DR. EVARISTO G. CIGANDA",
    "Escuela Nº47 - BRIG. GRAL. FRUCTUOSO RIVERA",
    "Escuela Nº48",
    "Escuela Nº49 - FRANCIA",
    "Escuela Nº50 - GRAL. JOSE GERVASIO ARTIGAS",
    "Escuela Nº51 - DARDO SALGUERO -  DELA HANTY",
    "Escuela Nº52 - DR. JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº53 - FRANCISCO ESPINOLA",
    "Escuela Nº54",
    "Escuela Nº55 - DR. JOSE ENRIQUE RODO",
    "Escuela Nº56 - CLAUDIA TAPIA DE ARBOLEYA",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60 - MARTIN ROGELIO CAMY",
    "Escuela Nº61",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº68 - GUYUNUSA",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79 - CARLOS JUSTINO MALAGUEZ",
    "Escuela Nº80 - CLEMENTE ESTABLE",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº83 - DANIEL FERNANDEZ CRESPO",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88 - DOMINGO F. SARMIENTO",
    "Escuela Nº89 - JUANA DE IBARBOUROU",
    "Escuela Nº90",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº93",
    "Escuela Nº95 - CAP. MANUEL ANTONIO ARTIGAS",
    "Escuela Nº96",
    "Escuela Nº97",
    "Escuela Nº98",
    "Escuela Nº99",
    "Escuela Nº100 - MANUEL GARCIA LOPEZ",
    "Escuela Nº101 - DR. CARLOS NUÑEZ",
    "Escuela Nº102 - AVIADOR RICARDO DETOMASI",
    "Escuela Nº103 - BRIG. GENERAL MANUEL ORIBE",
    "Escuela Nº104",
    "Escuela Nº105 - ORESTES ARAUJO",
    "Escuela Nº106 - ENRIQUE RODRIGUEZ FABREGAT",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112",
    "Escuela Nº113",
    "Escuela Nº115",
    "Escuela Nº117 - JAPON",
    "Escuela Nº118",
    "Escuela Nº119",
    "Escuela Nº120",
    "Escuela Nº121",
    "Escuela Nº122",
    "Escuela Nº123",
    "Escuela NºS/N1 - COLONIA ESCOLAR RAIGON",
  ],
  Soriano: [
    "Escuela Nº1 - JOSE PEDRO VARELA",
    "Escuela Nº2 - MARIA LUISA ANSELMI DE LYONNET",
    "Escuela Nº3 - TREINTA Y TRES ORIENTALES",
    "Escuela Nº4 - GRAL. JOSE GERVASIO ARTIGAS",
    "Escuela Nº6 - MAESTRO LUIS SAMPEDRO",
    "Escuela Nº7 - GERVASIA GALARZA DE IRASTORZA",
    "Escuela Nº8 - JUAN MANUEL TORREGLOSA",
    "Escuela Nº9 - MARTIN JOSE ARTIGAS",
    "Escuela Nº10 - JUAN ANTONIO LAVALLEJA",
    "Escuela Nº11 - ANGEL BRACERAS HAEDO",
    "Escuela Nº12",
    "Escuela Nº13",
    "Escuela Nº14",
    "Escuela Nº15 - MAESTRO AGUSTIN FERREIRO",
    "Escuela Nº16",
    "Escuela Nº17",
    "Escuela Nº18 - PRUDENCIO B. LAVISTA",
    "Escuela Nº19",
    "Escuela Nº20 - ALBERTO MEYER",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº24 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº25 - JOSEFA GANDOLFO DE PENSE",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº29",
    "Escuela Nº31",
    "Escuela Nº32",
    "Escuela Nº33",
    "Escuela Nº34 - MAESTRO RAUL VAZQUEZ LEDESMA",
    "Escuela Nº35 - MAESTRA MERCEDES GILARDONI",
    "Escuela Nº37 - DR. PABLO PURRIEL",
    "Escuela Nº38",
    "Escuela Nº39 - ESPAÑA",
    "Escuela Nº40",
    "Escuela Nº41 - JOSE ENRIQUE RODO",
    "Escuela Nº42 - JUAN JACKSON",
    "Escuela Nº43",
    "Escuela Nº44",
    "Escuela Nº45 - DR. EVARISTO G. CIGANDA",
    "Escuela Nº46 - DR. SERAFIN RIVAS RODRIGUEZ",
    "Escuela Nº47",
    "Escuela Nº48 - IBIRAY",
    "Escuela Nº49 - ENRIQUE OYHARZABAL PARDIAS",
    "Escuela Nº50",
    "Escuela Nº52",
    "Escuela Nº53",
    "Escuela Nº54 - FRANCISCA A. ARNAL DE ARTIGAS",
    "Escuela Nº56",
    "Escuela Nº57",
    "Escuela Nº58 - CLEMENTE ESTABLE",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61",
    "Escuela Nº63",
    "Escuela Nº64",
    "Escuela Nº65 - EDUARDO VICTOR HAEDO",
    "Escuela Nº66",
    "Escuela Nº67",
    "Escuela Nº71",
    "Escuela Nº72 - EDMUNDA G. ARISMENDI CHIAPPINI",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº76",
    "Escuela Nº80",
    "Escuela Nº82",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº92 - ELVIRA JUSTA PERE PERUZZO",
    "Escuela Nº94",
    "Escuela Nº95 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº96",
    "Escuela Nº97 - JUAN MANUEL BLANES",
    "Escuela Nº98 - ANA MONTERROSO DE LAVALLEJA",
    "Escuela Nº99 - GRITO DE ASENCIO",
    "Escuela Nº100",
    "Escuela Nº101 - DIONISIO DIAZ",
    "Escuela Nº102 - FERNAN SILVA VALDES",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº107",
    "Escuela Nº109 - MAESTRA TAMAR MENDEZ BALARINI",
    "Escuela Nº110",
    "Escuela Nº111 - JUANA DE IBARBOUROU",
    "Escuela Nº112 - CONSTANCIO C. VIGIL",
    "Escuela Nº113",
    "Escuela Nº115 - MAESTRA EGRISELDA GUTIERREZ BRAVO",
    "Escuela Nº116",
    "Escuela Nº117",
    "Escuela Nº119",
    "Escuela Nº120 - EDUARDO VICTOR BOGA",
    "Escuela Nº121",
    "Escuela Nº122",
    "Escuela Nº125",
  ],
  Tacuarembó: [
    "Escuela Nº1 - CESAR ORTIZ Y AYALA",
    "Escuela Nº2 - VICTORIA FRIGERIO",
    "Escuela Nº3 - J. AMERICO BEISSO",
    "Escuela Nº4 - DR.JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº5 - REPUBLICA DE ITALIA",
    "Escuela Nº6",
    "Escuela Nº7 - JOSE ARTIGAS",
    "Escuela Nº8 - JOSE PEDRO VARELA",
    "Escuela Nº9",
    "Escuela Nº10",
    "Escuela Nº11 - REPUBLICA FEDERATIVA DEL BRASIL",
    "Escuela Nº12 - REPUBLICA DEL PARAGUAY",
    "Escuela Nº13",
    "Escuela Nº16",
    "Escuela Nº17",
    "Escuela Nº18",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23 - CARLOS GARDEL",
    "Escuela Nº24",
    "Escuela Nº25",
    "Escuela Nº26 - Q. F.  LUIS S. DINI",
    "Escuela Nº27",
    "Escuela Nº28",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31",
    "Escuela Nº33",
    "Escuela Nº35",
    "Escuela Nº36",
    "Escuela Nº38",
    "Escuela Nº39",
    "Escuela Nº40",
    "Escuela Nº41 - DIONISIO DIAZ",
    "Escuela Nº42",
    "Escuela Nº43 - LUIS ALBERTO DE HERRERA",
    "Escuela Nº44",
    "Escuela Nº45",
    "Escuela Nº46",
    "Escuela Nº47",
    "Escuela Nº48",
    "Escuela Nº49",
    "Escuela Nº50 - CRISTOBAL COLON",
    "Escuela Nº51",
    "Escuela Nº52",
    "Escuela Nº53",
    "Escuela Nº54",
    "Escuela Nº55",
    "Escuela Nº57",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61 - AGUSTIN FERREIRO",
    "Escuela Nº62",
    "Escuela Nº63",
    "Escuela Nº64 - JOSE ENRIQUE RODO",
    "Escuela Nº65",
    "Escuela Nº67",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº70",
    "Escuela Nº71",
    "Escuela Nº72",
    "Escuela Nº73",
    "Escuela Nº74",
    "Escuela Nº75",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº81",
    "Escuela Nº82",
    "Escuela Nº83",
    "Escuela Nº84",
    "Escuela Nº85",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº89",
    "Escuela Nº90",
    "Escuela Nº91",
    "Escuela Nº92",
    "Escuela Nº93",
    "Escuela Nº94",
    "Escuela Nº98",
    "Escuela Nº99",
    "Escuela Nº100",
    "Escuela Nº101",
    "Escuela Nº102",
    "Escuela Nº103",
    "Escuela Nº104",
    "Escuela Nº105",
    "Escuela Nº106",
    "Escuela Nº107",
    "Escuela Nº108",
    "Escuela Nº109 - ADELAIDA PUYOL",
    "Escuela Nº110",
    "Escuela Nº111",
    "Escuela Nº112",
    "Escuela Nº113",
    "Escuela Nº114",
    "Escuela Nº115",
    "Escuela Nº116",
    "Escuela Nº117",
    "Escuela Nº118",
    "Escuela Nº121",
    "Escuela Nº122",
    "Escuela Nº124",
    "Escuela Nº126",
    "Escuela Nº127 - ESPAÑA",
    "Escuela Nº128",
    "Escuela Nº129",
    "Escuela Nº131",
    "Escuela Nº132",
    "Escuela Nº133",
    "Escuela Nº134",
    "Escuela Nº135",
    "Escuela Nº136",
    "Escuela Nº137 - REPUBLICA ARGENTINA",
    "Escuela Nº138",
    "Escuela Nº139",
    "Escuela Nº141",
    "Escuela Nº142",
    "Escuela Nº143",
    "Escuela Nº144 - CLEMENTE ESTABLE",
    "Escuela Nº145",
    "Escuela Nº146",
    "Escuela Nº147",
    "Escuela Nº148",
    "Escuela Nº149",
    "Escuela Nº150",
    "Escuela Nº151",
    "Escuela Nº152",
    "Escuela Nº154",
    "Escuela Nº155",
    "Escuela Nº156",
    "Escuela Nº157",
    "Escuela Nº160 - COLONIA ESCOLAR IPORA",
    "Escuela Nº161",
    "Escuela Nº162",
  ],
  "Treina y Tres": [
    "Escuela Nº1 - SEVERO RAMIREZ",
    "Escuela Nº2 - JOSE ARTIGAS",
    "Escuela Nº3 - MAESTRO RUBEN LENA",
    "Escuela Nº4 - PEQUEÑO DIONISIO",
    "Escuela Nº8",
    "Escuela Nº9 - ESPAÑA",
    "Escuela Nº10",
    "Escuela Nº12",
    "Escuela Nº14",
    "Escuela Nº15",
    "Escuela Nº16 - IGNACIO J. BONILLA",
    "Escuela Nº17 - SERAFIN J. GARCIA",
    "Escuela Nº19 - JOSEFINA MARGARITA IPAR DE MELAZZI",
    "Escuela Nº20",
    "Escuela Nº21",
    "Escuela Nº22",
    "Escuela Nº23",
    "Escuela Nº24",
    "Escuela Nº25 - JOSE PEDRO VARELA",
    "Escuela Nº26",
    "Escuela Nº27",
    "Escuela Nº28 - AGUSTIN FERREIRO",
    "Escuela Nº29",
    "Escuela Nº30",
    "Escuela Nº31 - JUANA IPAR DE IMIZCOZ",
    "Escuela Nº32 - REPUBLICA DE ITALIA",
    "Escuela Nº33",
    "Escuela Nº34",
    "Escuela Nº35",
    "Escuela Nº37",
    "Escuela Nº38",
    "Escuela Nº39 - JUANA BERNARDA IPUCHE MARIÑO",
    "Escuela Nº40",
    "Escuela Nº41",
    "Escuela Nº43",
    "Escuela Nº45 - FELICIANA  LACUESTA DE FLEITAS",
    "Escuela Nº46",
    "Escuela Nº47 - JUANA DE IBARBOUROU",
    "Escuela Nº48 - ISMENIA SANCHEZ DE MACHADO",
    "Escuela Nº49",
    "Escuela Nº50 - ROMULO GALLEGOS",
    "Escuela Nº51",
    "Escuela Nº55",
    "Escuela Nº56",
    "Escuela Nº57 - CLEMENTE ESTABLE",
    "Escuela Nº58",
    "Escuela Nº59",
    "Escuela Nº60",
    "Escuela Nº61 - LUIS CARLOS GARATTE",
    "Escuela Nº62",
    "Escuela Nº64 - POETA GRAL. EDGARDO UBALDO GENTA",
    "Escuela Nº65 - JUANA ELIZALDE DE URAN",
    "Escuela Nº66 - GRAL. FRUCTUOSO RIVERA",
    "Escuela Nº67 - INTENDENTE DR. VALENTIN COSSIO",
    "Escuela Nº68",
    "Escuela Nº69",
    "Escuela Nº73 - BRIGADIER GENERAL JUAN ANTONIO LAVALLEJA",
    "Escuela Nº74",
    "Escuela Nº76",
    "Escuela Nº77",
    "Escuela Nº78",
    "Escuela Nº79",
    "Escuela Nº80",
    "Escuela Nº81 - MAESTRA ENRIQUETA COMPTE Y RIQUE",
    "Escuela Nº82",
    "Escuela Nº83 - BRIGADIER GRAL. MANUEL ORIBE",
    "Escuela Nº84",
    "Escuela Nº85 - JUAN ZORRILLA DE SAN MARTIN",
    "Escuela Nº86",
    "Escuela Nº87",
    "Escuela Nº88",
    "Escuela Nº90",
    "Escuela Nº91",
  ],
};
