import React, { useEffect, useState } from "react";
import { sendPlayerData } from "../../data/dbService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Results = ({ score, questionsFiltered, onReset }) => {
  const [ranking, setRanking] = useState([]);

  const navigate = useNavigate();
  const name = useSelector((state) => state.nombre);
  const department = useSelector((state) => state.departamento);
  const school = useSelector((state) => state.escuela);
  const grade = useSelector((state) => state.grado);

  const playerData = {
    department,
    selectedSchool: school,
    grade,
    name,
    triviaScore: ((score / questionsFiltered.length) * 100).toFixed(0),
  };
  useEffect(() => {
    fetch("https://cooperativito-api.vercel.app/players/trivia-ranking")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Almacena los datos en el estado
        const top5Ranking = data.posicionesTrivia.slice(0, 5);
        setRanking(top5Ranking);
        console.log(
          "Ranking actualizado con posicionesTrivia:",
          data.posicionesTrivia
        );
      })
      .catch((error) => {
        console.error("Error fetching the ranking:", error);
      });
  }, []);

  const handleResetClick = () => {
    // Call the sendPlayerData function only when "Let's go again" is clicked
    sendPlayerData(playerData);
    // Call the onReset function to restart the game
    onReset();
    navigate("/seleccion-juegos");
  };

  return (
    <div className="bg-white flex flex-col justify-evenly items-center shadow-xl rounded-lg max-w-screen  m-10 text-white px-28 py-12 my-24">
      <h1 className="text-2xl font-bold text-lime-600 mb-7">Resultados</h1>

      <div className="flex flex-col  text-center text-lg font-bold text-green-900 mb-7">
        <span>Acertaste</span>
        <span className="font-black bg-gradient-to-rbg-clip-text  text-6xl ">
          {((score / questionsFiltered.length) * 100).toFixed(0)}%
        </span>
        de las preguntas ({score} de {questionsFiltered.length})
      </div>

      <button
        className="border px-5 py-2 rounded-lg transition-all font-normal font-inter bg-lime-600 text-white w-72 h-11"
        onClick={handleResetClick}>
        Finalizar partida
      </button>
      {ranking.length > 0 && (
        <div className="mt-20 w-full overflow-x-auto min-w-full -mx-4 sm:mx-0">
          <h2 className="text-xl text-green-900 font-bold font-inter text-center mb-7">
            Ranking
          </h2>
          <table className="border-collapse border border-gray-400 text-green-900 font-inter w-full">
            <thead className="text-xs">
              <tr>
                <th className="border border-transparent px-4 py-2 text-left">
                  Posición
                </th>
                <th className="border border-transparent px-4 py-2 text-left">
                  Departamento
                </th>
                <th className="border border-transparent px-4 py-2 text-left">
                  Escuela
                </th>
                <th className="border border-transparent px-4 text-left">
                  Puntaje promedio
                </th>
              </tr>
            </thead>
            <tbody className="text-lime-600 text-xl">
              {ranking.map((player, index) => (
                <tr key={index}>
                  <td className="border border-b-green-900 border-transparent px-4 py-2 text-green-900 font-bold ">
                    # {index + 1}
                  </td>
                  <td className="border border-transparent border-b-green-900 px-4 py-2">
                    {player.department}
                  </td>
                  <td className="border border-transparent border-b-green-900 px-4 py-2 font-bold">
                    {player.escuela}
                  </td>
                  <td className="border border-transparent border-b-green-900 px-4 py-2 font-bold items-center">
                    {player.averageTriviaScore}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
